import React, { useState, useEffect } from "react";
import Sub_header from './Sub_header'

import axios from "axios";


import exportValue from "../apiconfig";
import ReactLoading from 'react-loading';
import Swal from 'sweetalert2'
import Left_panel from "./Left_panel";
// import '../../components/loader.css';


const Rate_calculator = () => {
    const [productList, setproductList] = useState([]);
    const [state, setState] = useState({from_postal_code:"" , to_postal_code:"" , weight:"" , height:"" , width:"" , length:""});
   const[stateloading , setloadingstate] = useState({isloading:false})
   const[setvisible , setvisiblestate] = useState(false)
   const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

   const toggleSidebar = () => {
       setIsSidebarOpen(!isSidebarOpen);
       const homeSection = document.querySelector('.home-section');
       if (homeSection) {
           homeSection.classList.toggle('sidebar-closed');
       }
   };
    const getProductList = (index=0) => {

        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
    setloadingstate({isloading:true})
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/search_zone`;
        let sendData = {from_postal_code:state.from_postal_code, to_postal_code:state.to_postal_code , weight:state.weight , height:state.height , width: state.width , length:state.length}
          
       if(state.from_postal_code!="" && state.to_postal_code!="" && state.weight!="" && state.height!="" && state.width!="" && state.length!=""){
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
           if(res.data.status == true){
         setproductList(res.data.output)
         setloadingstate({isloading:false})
         setvisiblestate(true)
           }
           else{
            setproductList(res.data.message)
            setloadingstate({isloading:false})
            setvisiblestate(true)
           }
        

        }).catch((e) => {
            Toast.fire({
                background: "#206bc4",
                type: 'unsuccess',
                title: "Something went wrong",
                color: "white"
            });


        });
    }
    else{
        Toast.fire({
            background: "#206bc4",
            type: 'unsuccess',
            title: "Please Fill all the Details",
            color: "white"
        });
setloadingstate({isloading:false})

    }
    }

    const handleChange=(e)=>{
        let names= e.target.name
        console.log("names",names)
      setState({...state , [e.target.name]:e.target.value})
    }
  return (
    <div>
      <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?>  */}
           
       <Sub_header/>

      <div className=''>
    
        <div className='card' style={{marginTop:"20px"}}>
        <div className='card-body'>
            <div className='container'>
                <div className='row'  style={{marginTop:"50px"}}>
                    <div className='col-3'><strong>From</strong></div>
                    <div className='col-3'><strong>To</strong></div>
                    <div className='col-1'><strong>Weight</strong>
                      </div>
                      <div className='col-1'><strong>Height</strong></div>
                      <div className='col-1'><strong>Width</strong></div>
                      <div className='col-1'><strong>Length</strong></div>

</div>
                     <div className='row'  style={{marginBottom:"50px"}}>

                    <div className='col-3'>
                       <input className='form-control' name="from_postal_code" style={{height:"50px"}} placeholder=" Enter Pincode" onChange={(e)=>handleChange(e)}/>
                    </div>
                    <div className='col-3'>
                    <input className='form-control' name="to_postal_code" style={{height:"50px"}} placeholder=" Enter Pincode" onChange={(e)=>handleChange(e)}/>
                    </div>
                    <div className='col-1'>
                    <input className='form-control' name="weight" style={{height:"50px"}} placeholder="W" onChange={(e)=>handleChange(e)}/>
                    </div>
                    <div className='col-1'>
                    <input className='form-control' name="height" style={{height:"50px"}} placeholder="H" onChange={(e)=>handleChange(e)}/>
                    </div>
                    <div className='col-1'>
                    <input className='form-control' name="width" style={{height:"50px"}} placeholder="W" onChange={(e)=>handleChange(e)}/>
                    </div>
                    <div className='col-1'>
                    <input className='form-control' name="length" style={{height:"50px"}} placeholder="L" onChange={(e)=>handleChange(e)}/>
                    </div>
                    <div className='col-2'>
                    <button className='btn btn-primary' style={{height:"50px" , width:"100px"}} onClick={()=>getProductList()}>Submit</button>
                    </div>
                

                </div>
            </div>
        </div>
        </div>
        
        
{stateloading.isloading==true?
    <div style={{ backgroundColor: "#f1f5f9", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.4" }}>
                    <div style={{ left: "30%", top: "25%", position: "absolute" }}>
                        <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                    </div>
                </div>:

setvisible==true?
        <div class="card border-0 shadow mt-4 ">
          <div class="card-body">
            <section>
            <table class="table table-bordered">
                    <thead class="bg-dark">
                      <tr>
                        <th style={{color:"white"}}>Carrier</th>
                        <th style={{color:"white"}}>Product</th>
                        <th style={{color:"white"}}>Zone Name</th> 
                        {/* <th style={{color:"white"}}>Service</th> */}
                        <th style={{color:"white"}}>Charges</th>
                        <th style={{color:"white"}}>Carrier Commission</th>
                     
                     
                      </tr>
                    </thead>
                   
                    <tbody >
                      {productList.map((sub,index)=>(
                      <tr className="mt-4">
                       <td>
                        {/* <img src={`https://cdn.shipdartexpress.com/images/${sub.carrierImage}`} width="100px" /> */}
                       {sub.carrier_name} </td>
                        <td>{sub.service_provider}</td>
                        <td>{sub.zone_name}</td>
                        {/* <td>{sub.product_type_name}</td> */}
                        <td>{sub.total_charges}</td>
                        <td>
                            {sub.commission_charge}
                        </td>
                        
                      </tr>
                         ))} 
                    </tbody>

                   
                </table>
            </section>
            </div>
            </div>

      
    
                   :""   }
                   </div>
     </section>
    </div>
  )
}

export default Rate_calculator
