import React from 'react'
// import Customer_header from './Customer_header'
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import exportValue from '../../apiconfig'
// import Footer from './Footer'
// import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import Sub_header from '../Sub_header';
import queryString from 'query-string';
import Left_panel from '../Left_panel';
// import config from "../config"
// import Moment from 'react-moment';
// import Header_nav from './Header_nav';
// import download from 'downloadjs'
// import Shipment_steps from './Shipment_steps';
const Allot_warehouse_store_keeper = () => {
     const {address_id} = useParams();
  let navigate = useNavigate()
  const location = useLocation()
  console.log("location==>122", location)
   let  parsed = queryString.parse(location.search);
   console.log("parsed",parsed);
  const [defaultCountry, setDefaultCountry] = React.useState({country_id:0})
    const [isLoading , setIsLoading] = React.useState(false)
  const [selectedOption, setSelectedOption] = React.useState(null);
  console.log("selectedOption === " , selectedOption)
  const [selectedOption1, setSelectedOption1] = React.useState(null);

  const [options, setOptions] = React.useState([]);
  const [assigncustomer, setassigncustomer] = React.useState([]);

  const[addstate , addSetState] = React.useState({receiver_name:"",receiver_email:"",receiver_mobile:"",receiver_address:"",receiver_country: "", receiver_country_code: "", receiver_postal_code: "", receiver_city: "", receiver_state_name:"",receiver_city_id:"" , receiver_state_id:"", mode:"",error:{} , customer_id:""});
  console.log("addstate==== " , addstate)
  const[domesticmode,setdomesticmodeState] = React.useState({domestic:false})
  const [returnstate, setReturnState] = React.useState({return_name:"",return_city:"",return_country_code:"",return_postal_code:"", return_mobile: "", return_address: "", return_email: "",return_state_name:"" , return_country:""})
  console.log("returnstate === " , returnstate)
 
  const [countryList, setcountryList] = React.useState([]);
  console.log("country == " , countryList)
  const [stateList, setStateList] = React.useState([]);
  const [cityList, setCityList] = React.useState([]);
  const [pincodeList, setPincodeList] = React.useState([]);
  const[country,setCountry] = React.useState({country_id:""})
  const[state,setState] = React.useState({state_id:""})
  const[city,setCity] = React.useState({city_id:""})
  
  const[customersState , setCustomers]=React.useState([])
  const[customeroption , setCustomerOption]=React.useState([])
  // const[selectedOption1 , setSelectedOption1]=React.useState(null)

  
  
  // let userDetail = localStorage.getItem('ship_rocket_user');
  // // console.log("userDetail====", config.UserId);
  // let userData = {};
  // if (userDetail != null) {
  //   userData = JSON.parse(userDetail)
  // }
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };
  React.useEffect(()=>{
      customers_all()
      assigned_customers_all()
    },[])

  const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000
    })
 
    
  
    const customers_all= ()=>{
      let url = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/all_store_keeper`;
      let sendData = {};
      console.log("bb", sendData);
      axios.post(url,sendData ,{headers:exportValue.headers}).then((res)=>{
          if(res.data.status=="success"){
              console.log("sasdfdsa")
              setCustomers(res.data.output)
              let data = res.data.output
              const customerOptions = data.map(item => ({
               value: item.admin_id, // Set the value property based on your data
               label: item.full_name // Set the label property based on your data
             }));
             setCustomerOption(customerOptions)
              // setaddressState(res.data.output)
          }

      }).catch(()=>{
          const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 3000
            })
            
            Toast.fire({
              background:"#206bc4",
              type: 'unsuccess',
              title: "Something Went Wrong",
              color:"white"
            });
     
         
      })
     }

     const assigned_customers_all= ()=>{
        setIsLoading(true)
        let url = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/allot_warehouse_store_keeper_list`;
        let sendData = {address_id:address_id};
        console.log("bb", sendData);
        axios.post(url,sendData ,{headers:exportValue.headers}).then((res)=>{
            if(res.data.status==true){
                console.log("sasdfdsa")
                setassigncustomer(res.data.output)
                setIsLoading(false)
            }
            else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"#206bc4",
                    type: 'unsuccess',
                    title: "Something Went Wrong",
                    color:"white"
                  });
            }
  
        }).catch(()=>{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"#206bc4",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
       
           
        })
       }
     const handleSelectChange1 = selectedOption => {
        console.log("onClick === " ,selectedOption.value )
      setSelectedOption1(setSelectedOption1);
      addSetState({...addstate , customer_id:selectedOption.value})
      let url =  exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/allot_warehouse_to_store_keeper`;
      let sendData = {store_keeper_id:selectedOption.value , address_id:address_id};
     
      axios.post(url, sendData, { headers: exportValue.headers }).then((res) => {
        if(res.data.status==true){
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"#206bc4",
                type: 'success',
                title: "Warehouse Allotted",
                color:"white"
              });
              assigned_customers_all()
        }
        else{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"#206bc4",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
        }
        
  
  
      }).catch((e) => {
  
  const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 3000
            })
            
            Toast.fire({
              background:"#206bc4",
              type: 'unsuccess',
              title: "Something Went Wrong",
              color:"white"
            });
      });
    };

    
  
  return (
    <div>
         <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>
 {/* <Shipment_steps value={1}/> */}
    <div class="row">
    <div class="col-12 col-md-12 mb-5">
        
        <div class="card border-0 shadow-sm bg-light">
          <div class="card-body">
          <h4 class="mb-3">Select Store Keeper</h4>
            <section class="mb-3">
              <div>
              <Select
    styles={{
        control: (provided, state) => ({
          ...provided,
          minHeight: '58px', 
        }),
      }}
      placeholder="Select Store Keeper"
      options={customeroption}
      value={selectedOption1}
      onChange={(e)=>handleSelectChange1(e)}
      isClearable={true}
      name="customer"
    />
               
              </div>
              
            </section>
          </div>
        </div>


        
      </div>

     </div>
    


  
                    <div className="card card-body border-0 shadow table-wrapper table-responsive">
                        <table className="table table-hover">
                            <thead style={{background:"black"}}>
                                <tr>
                                    <th style={{color:"white"}}>#</th>
                                    <th style={{color:"white"}}>Name</th>
                                    <th style={{color:"white"}}>Email</th>
                                    <th style={{color:"white"}}>Mobile #</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                {/* <!-- Item --> */}
                                {assigncustomer.map((user, index) =>
                                    <tr>
                                      <td>{user.admin_id}</td>
                                        <td>{user.full_name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.phone}</td>
                                       

                                    </tr>
                                )}
                               
                            </tbody>
                        </table>
{/* 
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 10]}
                            count={otherStates.total_count}
                            page={otherStates.page}
                            onPageChange={handlePageChange}
                            rowsPerPage={otherStates.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}

                        />
                      */}

                    </div>
</section>


    </div>
  )
}

export default Allot_warehouse_store_keeper
