import React, { useState, useEffect } from "react";
import axios from "axios";
import Moment from 'react-moment';
import ReactLoading from 'react-loading';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import TablePagination from '@mui/material/TablePagination';
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel'
import Sub_header from '../Sub_header'
import '../../components/loader.css';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'



const Confirm_stock = () => {
    const[state,setState] = React.useState([])
    const[productlist,setProductlist] = React.useState([])
    const[statewarehouse , setStatewarehouse]= React.useState([])

    const[statevariants , setStatevariants]= React.useState([])

    const[productstate , setProductState]= React.useState({product_id:""})
    const[warehouseset , setwarehousesset]= React.useState({warehouse_id:""})
    const[remarkstate , setremarkstate]= React.useState({remark:""})

    const [variantQuantitiesadd, setVariantQuantitiesadd] = React.useState([]);
    console.log("variantQuantitiesadd == ", variantQuantitiesadd)
    
    const[valuestate , setvaluestate] = React.useState({approved_value:0})
    const [stock , setstock] = React.useState({stock_id:""})
    const [stockentry , setstockentry] = React.useState({})
    console.log("stockentry == " , stockentry)

    const [showModal, setshowModal] = useState({show:false});
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const [variantQuantities, setVariantQuantities] = React.useState([]);
    const [modalState, setModalState] = React.useState({ show: false });
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 5000
    })

React.useEffect(()=>{
    confirm_stocks_listing()
},[])
    const confirm_stocks_listing = ()=>{
        let url = exportValue.host + "/" + exportValue.version + "/" + exportValue.api  + '/stock_entries_list';
        let sendData = { };
        // console.log("bb", sendData);
        axios.post(url,sendData ,{headers:exportValue.headers}).then((res)=>{
            if(res.data.status==true){
                setState(res.data.output) 
            }

        }).catch(()=>{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
       
           
        })
       }

       const openModal=(sub)=>{
        setshowModal({show:true})
        setstock({stock_id:sub.stock_id})
        setstockentry({sub})

       }

       const handleChange=(e)=>{
        setvaluestate({...valuestate , approved_value:e.target.value})
       }

       const addUnits = ()=>{
        let url = exportValue.host + "/" + exportValue.version + "/" + exportValue.api  + '/approve_stock_entries';
        let sendData = {stock_id :stock.stock_id , stockentry };
        // console.log("bb", sendData);
        axios.post(url,sendData ,{headers:exportValue.headers}).then((res)=>{
            if(res.data.status==200){
                // setState(res.data.output) 
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"rgb(231, 91, 75)",
                    type: 'success',
                    title: res.data.message,
                    color:"white"
                  });
                confirm_stocks_listing()
                setshowModal({show:false})
                setstockentry({})
                
            }
            else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"rgb(231, 91, 75)",
                    type: 'unsuccess',
                    title: res.data.message,
                    color:"white"
                  });
                confirm_stocks_listing()
                setshowModal({show:false})
                setstockentry({})
            }

        }).catch(()=>{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
       
           
        })
       }
       const handleQuantityChange = (variantId, newValue, value) => {
        // Assuming you have an array of variants in your state
        const updatedVariants = stockentry.sub.variants.map((variant) => {
          if (variant.variant_id === variantId) {
            // Update the stock value of the variant
            return {
              ...variant,
              stock: newValue,
            };
          }
          return variant;
        });
      
        // Update the state with the new array of variants
        setstockentry({
          ...stockentry,
          sub: {
            ...stockentry.sub,
            variants: updatedVariants,
          },
        });
      };

      const clickAddStock = ()=>{
        setModalState({show:true})
      
            let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api  + `/product_list`;
            let sendData = {};
          
            axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
                setProductlist(res.data.output)
            }).catch((e) => {
              
            })

            let full_api_new = exportValue.host + "/" + exportValue.version + "/" + exportValue.api  + `/fetch_warehouse`;
            let sendData_new = {};
          
            axios.post(full_api_new, sendData_new, { headers: exportValue.headers }).then(res => {
                setStatewarehouse(res.data.output)
            }).catch((e) => {
              // toast.configure()
               //toast.error("Some thing went wrong")
              // console.log("----error:   ", e);
            })
          
    }

    const handleSelect = (e)=>{
        setProductState({...productstate , product_id:e.target.value})
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/product_list_with_id`;
        let sendData = {product_id:e.target.value};
      
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
           setStatevariants(res.data.output)
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
       }
   
     
       
          const selectWarehouse =(e)=>{
           setwarehousesset({...warehouseset , warehouse_id:e.target.value})
          }
          const selectRemark =(e)=>{
           setremarkstate({...remarkstate , remark:e.target.value})
          }
          
   
          const handleQuantityChangeadd = (variantId, quantity, variantName) => {
           // Find the index of the variant in the variantQuantities array
           const variantIndex = variantQuantitiesadd.findIndex(v => v.id ==variantId);
           console.log("variantIndex ==  , " , variantIndex)
   
         
           if (variantIndex != -1) {
             // Variant already exists in the array, update its quantity
             const updatedVariant = { ...variantQuantitiesadd[variantIndex], quantity };
             const updatedVariantQuantities = [...variantQuantitiesadd];
             updatedVariantQuantities[variantIndex] = updatedVariant;
             setVariantQuantitiesadd(updatedVariantQuantities);
           } else {
             // Variant does not exist in the array, add it with its quantity
             const newVariant = { id: variantId, name: variantName, quantity };
             setVariantQuantitiesadd(prevQuantities => [...prevQuantities, newVariant]);
           }
         };

         const handleAddStock = () => {
         
            // Construct data to send to backend
            if(warehouseset.warehouse_id!=undefined && warehouseset.warehouse_id!="" && productstate.product_id!=undefined && productstate.product_id!="" && remarkstate.remark!=undefined && remarkstate.remark!="" && variantQuantitiesadd.length>0 ){
                const data = {
                    warehouse_id: warehouseset.warehouse_id,
                    product_id: productstate.product_id,
                    variant_quantities:variantQuantitiesadd,
                    remark: remarkstate.remark, // Get the remark value from the input field
                  };
                  console.log("data=== " , data)
        
            // Send data to backend API
            axios.post(`${ exportValue.host + "/" + exportValue.version + "/" + exportValue.api}/add_stock`, data, { headers: exportValue.headers })
              .then(response => {
                // Handle success responseif
                if(response.data.status==200){
                console.log('Stock added successfully:', response.data);
                Swal.fire({
                    icon: 'success',
                    title: 'Oops...',
                    text: response.data.message,
                  });
                  setModalState({show:false})
                  confirm_stocks_listing()
                }
                else if(response.data.status==404){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                      });
                  setModalState({show:false})
                  confirm_stocks_listing()
    
                }
                else if(response.data.status==500){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                      });
                  setModalState({show:false})
                  confirm_stocks_listing()
    
                }
              })
              .catch(error => {
                // Handle error response
                console.error('Error adding stock:', error);
              });
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'All fields are required!',
                  });
            }
          };

  return (
    <div>
   <Modal show={showModal.show} onHide={() => setshowModal({show:false})}>
     <Modal.Header closeButton>
                         <Modal.Title>
                       
             <h4 class="modal-title"> Add Approved Units</h4>
           
        
                         </Modal.Title>
                     </Modal.Header>
                     <Modal.Body >
                     <div className='row'>
                         <div className='col-12'>
                             
                             <table class="table table-bordered">
                  <thead style={{background:"black"}}>
                    <tr>
                      <th style={{color:"white"}}>Variants</th>
                      <th style={{color:"white"}}>Item Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stockentry.sub?
                    stockentry.sub.variants.map((sub)=>(
                    <tr>
                      <td>{sub.value}</td>
                      <td><input type="text" class="form-control" id="text" placeholder="Enter details name" onChange={(e) => handleQuantityChange(sub.variant_id, e.target.value, sub.value)}  value={sub.stock}/></td>
                    </tr>
                    )):""}
                  
                  </tbody>
                </table>
                         </div>
                      
                     </div>
                    
                     <hr/>
           <div class="p-3 text-center">
            <a  class="btn btn-primary" onClick={() => addUnits()}>Submit</a>
           </div>
                     </Modal.Body>
                   
                </Modal> 

  
   
        <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

<section class="home-section">
       {/* <?php
       include('sub_header.php');
       ?> 
    */}
<Sub_header/>
       
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="#">
                                    <svg className="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Stock Entries</li>
                        </ol>
                    </nav>
                   
                  
                </div>
                {/* <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="input-group me-2 me-lg-3 fmxw-400">
                        <span className="input-group-text"  onClick={() => getUsersList(0, true)}>
                            <svg className="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                            </svg>
                        </span>
                        <input type="text" className="form-control" placeholder="Search Store Keepers" name="searchtitle" onChange={(e) => stateHandler(e)}/>
                    </div>
                    <a className="btn btn-sm btn-gray-800 d-inline-flex align-items-center" onClick={() => setNewModalState({ show: true })}>
                        <svg className="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                        Add New Store Keeper
                    </a>
                </div> */}
            </div>

            <div className="row mb-2">
                        <div className="col-8">  <h2 className="h4">Stock Entries</h2></div>
                        <div className="col-4 text-end"> <a class="btn btn-primary btn-sm " onClick={(e)=>clickAddStock(e)} >Add Stock</a></div>
                    </div>

            {/* <section>
                <ul className="nav nav-tabs justify-content-end">
                    <li className="nav-item">
                        <a className="nav-link active" href="#">All</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Active</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Inactive</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Deleted</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Suspeded</a>
                    </li>
                </ul>
            </section> */}
            <div className="card card-body border-0 shadow table-wrapper table-responsive">
                <table className="table table-hover">
                    <thead style={{background:"black"}}>
                        <tr>
                        <th style={{color:"white"}}>Date</th>
                                        <th style={{color:"white"}}>SKU #</th>
                                        <th style={{color:"white"}}>Product Name</th>
                                        <th style={{color:"white"}}>Warehouse </th>
                                        {/* <th style={{color:"white"}}>Customer Details</th> */}
                                        <th style={{color:"white"}}>Vendor Added Units</th>
                                        <th style={{color:"white"}}>Approved Units</th>
                                       
                                        <th style={{color:"white"}}>Mode</th>
                                        <th style={{color:"white"}}>Status</th>
                                        <th style={{color:"white"}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                                        {state.map((sub)=>(
                                        <tr class="ship-now-container">
                                            <td>
                                            <Moment format="MMM DD YYYY">
                         {new Date(sub.created*1000)}
             </Moment> - <Moment format="hh:mm:ss a">
                         {new Date(sub.created*1000)}
             </Moment>
                                            </td>
                                            <td>
                                                <a href="product_detail.php">#{sub.product_detail.sku_code}</a>
                                            </td>
                                            <td>
                                                <a href="product_detail.php">{sub.product_detail.product_name}</a>
                                                {sub.product_detail.variants.map((sub)=>( <p>{sub.value}</p>))}
                                            </td>
                                            <td>
                                            {sub.warehouse_name}
                                            </td>
                                            {/* <td>
                                                <h4><a href="">{userData.full_name}</a></h4>
                                                <small>Account #{userData.customer_id}</small>
                                            </td> */}
                                            <td>
                                                {sub.vendor_added_units}
                                            </td>
                                            <td>
                                                {sub.approved_units}
                                            </td>
                                           
                                            <td>
                                               {sub.mode==1? <small><span class="badge rounded-pill bg-success fw-400">In Bound</span></small>:  <small><span class="badge rounded-pill bg-danger fw-400">Out Bound</span></small>}
                                            </td>
                                            <td>
                                                {sub.status==0? <small><span class="badge rounded-pill bg-warning fw-400">Pending</span></small>:sub.status==1? <small><span class="badge rounded-pill bg-success fw-400">Confirmed</span></small>: <small><span class="badge rounded-pill bg-danger fw-400">Rejected</span></small>}
                                               
                                            </td>
                                            {sub.status==0?<td><button className="btn btn-primary" onClick={(e)=>openModal(sub)}>Confirm</button></td>:""}
                                        </tr>
                                        ))}
                                       
                                    </tbody>
                </table>

                {/* <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10]}
                    count={otherStates.total_count}
                    page={otherStates.page}
                    onPageChange={handlePageChange}
                    rowsPerPage={otherStates.rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}

                /> */}
                

            </div>


        </section>
        <Modal show={modalState.show} onHide={() => setModalState({ show: false })} >
 <Modal.Header closeButton>
                     <Modal.Title>
                     <h6 class="modal-title">Add Stock</h6>
                     </Modal.Title>
                 </Modal.Header>
                 <Modal.Body >
                 <div class="modal-body">
        <div class="row">
            <div class="col-12 col-md-12 mb-3">
              <label for="email" class="form-label">Select Product:</label>
              <select class="form-select" onChange={(e)=>handleSelect(e)}>
                <option>Select</option>
                {productlist.map((sub)=>(
                    <option value={sub.product_id}>{sub.product_name}</option>
                ))}
              </select>
            </div>
            <div class="col-12 col-md-12 mb-3">
              <label for="email" class="form-label">Select Warehouse:</label>
              <select class="form-select" onChange={(e)=>selectWarehouse(e)}>
                <option>Select</option>
                {statewarehouse.map((sub)=>(
                <option value={sub.address_id}>{sub.warehouse_name}</option>))}
               
              </select>
            </div>
            <div class="col-12 col-md-12 mb-3">
              <label for="email" class="form-label">Enter Item Quantity:</label>
              <table class="table table-bordered">
                  <thead style={{background:"black"}}>
                    <tr>
                      <th style={{color:"white"}}>Variants</th>
                      <th style={{color:"white"}}>Item Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {statevariants.map((sub)=>(
                    <tr>
                      <td>{sub.value}</td>
                      <td><input type="text" class="form-control" id="text" placeholder="Enter details name" onChange={(e) => handleQuantityChangeadd(sub.variant_id, e.target.value, sub.value)} /></td>
                    </tr>
                    ))}
                  
                  </tbody>
                </table>
            </div>
            <div class="col-12 col-md-12 mb-3">
              <label for="email" class="form-label">Remark:</label>
              <input type="text" class="form-control" id="text" placeholder="Enter Order Number" onChange={(e)=>selectRemark(e)}/>
            </div>
        </div>
        {/* <small>Added stock will be verified by the store keeper and active only after the approval. </small> */}
      </div>
      <div class="modal-footer">
        <a href="#" class="btn btn-primary" onClick={(e)=>handleAddStock(e)}>Add Stock</a>
        <button type="button" class="btn btn-danger" onClick={() => setModalState({ show: false })}>Close</button>
      </div>
    
                </Modal.Body>
               
            </Modal>
</div>
  )
}

export default Confirm_stock
