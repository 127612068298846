import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import ReactLoading from 'react-loading';

import TablePagination from '@mui/material/TablePagination';
const B2b_Product_details = () => {
    const { product_id, carrier_id } = useParams();
    const [categorysave, setcategorysave] = useState({ destination_category: "" })
    const[stateid , setstateid] = React.useState({from_state_id:""})
    const [catlist, setcatlist] = useState([])
    const [catprice, setcatprice] = useState([])
    // const [catprice, setcatprice] = useState([]); 
  const[allstate , setallstates] = React.useState([])
    const [weightlist, setweightlist] = useState([])
    const [setedit, seteditstate] = useState([])
    const[stateLoading,setLoading]=React.useState({isLoading:false})
    const [addweightlist, setaddweightlist] = useState(0)
    const [rowstate, setRowState] = React.useState([])
    console.log("rowState", rowstate)
    const[cpscstate , setcpsc] = React.useState({carrier_name:[] , product_name:[] , source_city_name:[]})
     const[checkimport, setcheckimport] = React.useState([])
     const [buttonText, setButtonText] = useState('Save Price'); 
     const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
     const [buttonstate, setbuttonstate] = React.useState(false);


const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};
const[state, setState] = React.useState([])

   

    React.useEffect(() => {
        // categoryList();
        sourceproductname()
        edit_pricing()
        check_import_file_running()
        states()
    }, [])

    const states = ()=>{
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/fetch_all_states`;
        let sendData = { 
         }
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
            setallstates(res.data.output)
          
            }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
  
            });
       }
    const sourceproductname = ()=>{
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/product_carrier_city_mode_name`;
        let sendData = {  product_id:product_id , carrier_id:carrier_id 
         }
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
        
                 setcpsc({carrier_name:res.data.carrier_name , product_name: res.data.product_name })
               
  
            }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
  
            });
       }
       const check_import_file_running = ()=>{
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/check_import_process_run`;
        let sendData = {  product_id:product_id , carrier_id:carrier_id 
         }
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
        
                 setcheckimport(res.data.output)
               
  
            }).catch((e) => {
              console.log("e",e)
            });
       }
       const edit_pricing = ()=>{
        let category_id = ""
      
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/edit_product_category_api`;
        let sendData = {  product_id:product_id , carrier_id:carrier_id  
         }
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res)=>{
        
               setRowState(res.data.output)
               
  
            }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
  
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
  
            });
       }

       const onStateChange=(e)=>{
        setstateid({...stateid , state_id:e.target.value})
        setLoading({isLoading:true})
        setbuttonstate(true)
            let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/b2b_carrier_product_category_list_api`;
            let sendData = { product_id: product_id, carrier_id: carrier_id , from_state_id:e.target.value};
            console.log("sendData", sendData)
    
            axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
                console.log("res ", res);
                setcatlist(res.data.product_category)
                setweightlist(res.data.product_weight_range)
                setcatprice(res.data.cat_price)
                setState(res.data.cat_price)
    
                setLoading({isLoading:false})
    
    
            }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
    
                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
    
            });
    
        
       }
   
    const categoryList = () => {
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/carrier_product_category_list_api`;
        let sendData = { product_id: product_id, carrier_id: carrier_id };
        console.log("sendData", sendData)

        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
            setcatlist(res.data.product_category)
            setweightlist(res.data.product_weight_range)
            setcatprice(res.data.cat_price)
            setState(res.data.cat_price)

            setLoading({isLoading:false})


        }).catch((e) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
            })

            Toast.fire({
                background: "#206bc4",
                type: 'unsuccess',
                title: "Something went wrong",
                color: "white"
            });

        });

    }



    const pricechange = (e,name, start_range, end_range, to_state_id , additional_weight) => {
        console.log(start_range, ",", end_range, ",", to_state_id)

        let adminCList = [...rowstate]
        // let updatedCatPrice = [...catprice];
   if (adminCList.length > 0) {
           let iu =  adminCList.find(sub => sub.start_range == start_range && sub.end_range == end_range && sub.to_state_id == to_state_id);
           console.log("sub", iu)
           if(iu){
            let index = adminCList.indexOf(iu);
            console.log("sub =>  ", adminCList[index])
            adminCList[index][name] = e.target.value
           }else {
            let array = { additional_price: 0, to_state_id: to_state_id, carrier_id: carrier_id, product_id: product_id, start_range: start_range, end_range: end_range };
            if(name == "price") {
                array.price= e.target.value;
            }else {
                array.additional_price= e.target.value;
                
                array.additional_weight = (additional_weight)?additional_weight:0
            }
            adminCList.push(array)

           }
          
      
        }
        else {
            let array = { additional_price: 0, to_state_id: to_state_id, carrier_id: carrier_id, product_id: product_id, start_range: start_range, end_range: end_range };
            if(name == "price") {
                array.price= e.target.value;
            }else {
                array.additional_price= e.target.value;
                array.additional_weight= (additional_weight)?additional_weight:0
            }
            adminCList.push(array)
        }
        
        setRowState(adminCList)
        setButtonText("Save Price")

    }


    const editpricechange = (e, name, start_range, end_range, to_state_id, additional_weight , weightlist) => {
        console.log(start_range, "start_range ======", end_range, ",", to_state_id);
        console.log("name ====", e.target.value);
    
        let updatedCatPrice = [...catprice];
        console.log("updatedCatPrice == " , updatedCatPrice)
        let existingZone = updatedCatPrice.find((state_id) => state_id.state_id === to_state_id);
        console.log("existingZone == "  , existingZone)
    
        if (!existingZone) {
            // Create a new zone if it doesn't exist
            existingZone = {
                to_state_id: to_state_id,
                price_range: []
            };
            updatedCatPrice.push(existingZone);
        }
    
        // Ensure that the price_range array exists before finding an existing price
        if (!existingZone.price_range) {
            existingZone.price_range = [];
        }
    
        // Check if there is an existing price within the zone's price_range array
        let existingPrice = existingZone.price_range.find(
            (price) => price.start_range === start_range && price.end_range === end_range
        );
        console.log("existingPrice == " , existingPrice)
    
        if (!existingPrice) {
            // Create a new price object if it doesn't exist
            existingPrice = {
                start_range: start_range,
                end_range: end_range,
                price: "",
                additional_price: "",
                to_state_id : to_state_id
            };
            existingZone.price_range.push(existingPrice);
        }
    
        // Separate updates for price and additional_price based on the 'name' parameter
        if (name === "price") {
            existingPrice.price = e.target.value || "";
        } else if (name === "additional_price") {
            existingPrice.additional_price = e.target.value || "";
        }
    
        setcatprice(updatedCatPrice);
        setButtonText("Edit Price")
        console.log("updatedCatPrice === ", updatedCatPrice);
    };   
    
    
    const savepricing = () => {
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/b2b_carrier_product_destination_category_add_api`;
        let sendData = {weight_range : rowstate  , from_state_id :stateid.state_id};
        // console.log("sendData", sendData)
      

        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
            if (res.data.status == "success") {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })

                Toast.fire({
                    background: "#206bc4",
                    type: 'success',
                    title: "Added Sucessfully",
                    color: "white"
                });
                // onStateChange()
            }
            else {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })

                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
            }
        }).catch((e) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
            })

            Toast.fire({
                background: "#206bc4",
                type: 'unsuccess',
                title: "Something went wrong",
                color: "white"
            });

        });
    }

    const updatepricing = () => {
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/b2b_carrier_product_destination_category_edit_api`;
        let sendData =  {catprice , product_id , carrier_id , from_state_id:stateid.state_id };
        // console.log("sendData", sendData)
      
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
            if (res.data.status == "success") {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })

                Toast.fire({
                    background: "#206bc4",
                    type: 'success',
                    title: "Updated Sucessfully",
                    color: "white"
                });
                // onStateChange()
            }
            else {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })

                Toast.fire({
                    background: "#206bc4",
                    type: 'unsuccess',
                    title: "Something went wrong",
                    color: "white"
                });
            }
        }).catch((e) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
            })

            Toast.fire({
                background: "#206bc4",
                type: 'unsuccess',
                title: "Something went wrong",
                color: "white"
            });

        });
    }

  

    return (
        <div>
           <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>
           
                <section>
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <div class="d-block mb-4 mb-md-0">
                            <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                                <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                    <li class="breadcrumb-item">
                                        <a href="#">
                                            <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                        </a>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">Products</li>
                                </ol>
                            </nav>
                            <div class="d-flex">
                                <div class="">
                                    <h2 class="h4">{cpscstate.product_name.map((sub)=>(
                                        sub.product_name
                                    ))}</h2>
                                </div>
                                <div class="ps-2 ">
                                    <h5><span class="badge rounded-pill bg-dark">Carrier: {cpscstate.carrier_name.map((sub)=>(
                                        sub.carrier_name
                                    ))} </span></h5>
                                </div>
                                <div class="ps-2 ">
                                    <h5><span class="badge rounded-pill bg-warning">Mode: {cpscstate.product_name.map((sub)=>(
                                        sub.mode
                                    ))} </span></h5>
                                </div>
                            </div>
                        </div>
                       
                      
                        
                    </div>

                    <div className="col-md-4">
                       <select className="form-select" name="state_id" onChange={(e)=>onStateChange(e)}>
                      <option >Please Select</option>
                     {allstate.map((sub)=>( <option value={sub.state_id}>{sub.state_name}</option>))}

                       </select>
                    </div>

                </section>
                {(stateLoading.isLoading) ?
                <div style={{ backgroundColor: "#f1f5f9", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.4" }}>
                    <div style={{ left: "47%", top: "25%", position: "absolute" }}>
                        <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                    </div>
                </div> :

                <section>
                    <section>
                        <ul class="nav nav-tabs justify-content-end">
                            <li class="nav-item">
                                <a class="nav-link active" href="#">Price Chart</a>
                            </li>
                           
                        </ul>
                    </section>




                    <div class="card border-0 shadow ">
                        <div class="card-body">
                            <section class="zone_list">

                                <div class="table-responsive">
                                   
                                    <table class="table table-hover">
                                        
                                        <thead class="bg-dark1">
                                            <tr>
                                                <th style={{color:"white"}}>STATE</th>
                                                {weightlist.map((sub, index) => (
                                                    (!sub.isAdditional)?<th style={{color:"white"}}>{sub.start_range}-{sub.end_range} KG</th>:<th style={{color:"white"}}>Additional {sub.weight_range}/KG</th>

                                                ))}
                                               
                                            </tr>
                                        </thead>
                                        {catprice.length==0 ?
                                        <tbody>
                                            {catlist.map((sub, index) => (
                                                <tr>

                                                    <td class="origin_zone_name">{sub.state_name}</td>
                                                    {weightlist.map((item, index1) => (
                                                        <>
                                                        <td>
                                                            <input type="text" class="form-control" id="text" placeholder="Enter price" name="price" onChange={(e) => pricechange(e,(!item.isAdditional) ?"price":"additional_price", item.start_range, item.end_range, sub.state_id , item.weight_range)}  />
                                                        </td>
                                                       
                                                    </>
                                                    ))}
                                                </tr>
                                            ))}

                                        </tbody>
                                        :
                                        <tbody>
                                            {catprice.map((sub, index) => (
                                                <tr key={sub.state_id}>

                                                    <td class="origin_zone_name">{sub.state_name}</td>
                                                    
                                                   
                                                     {weightlist.map((item, index1) => (
        <td key={index1}>
          {sub.price_range ?
            sub.price_range.map((priceItem) => {
              if (
                priceItem.to_state_id === sub.state_id &&
                priceItem.start_range === item.start_range &&
                priceItem.end_range === item.end_range
              ) {
                return (
                  <input
                    type="text"
                    className="form-control"
                    id="text"
                    placeholder="Enter price"
                    name={
                      priceItem.price !== null
                        ? 'price'
                        : 'additional_price'
                    }
                    onChange={(e) =>
                      editpricechange(
                        e,
                        priceItem.price !== null
                          ? 'price'
                          : 'additional_price',
                        priceItem.start_range,
                        priceItem.end_range,
                        sub.state_id,
                        priceItem.additional_weight,
                        weightlist
                      )
                    }
                    value={
                      priceItem.start_range === 0 &&
                      priceItem.end_range === 0
                        ? priceItem.additional_price
                        : priceItem.price
                    }
                  />
                );
              }
              
            })    : 
          
                
                    <input type="text" class="form-control" id="text" placeholder="Enter price" name="price" onChange={(e) => pricechange(e,(!item.isAdditional) ?"price":"additional_price", item.start_range, item.end_range, sub.state_id , item.additional_weight )}  />
                
               
            
            }
        </td>
      ))}
                                                 
                                                </tr>
                                            ))}

                                        </tbody>
                                        }
                                     
                                    </table>

                                </div>
                            </section>


{buttonstate?
                           checkimport.length==0?
                          
                            <div class="text-end">
                                {buttonText=="Save Price"?
                            <button type="button" class="btn btn-primary btn-lg" onClick={savepricing}>Save Pricing</button>
                            :
                            <button type="button" class="btn btn-primary btn-lg" onClick={updatepricing}>Edit Pricing</button>
                             }
                        </div>
                         
                      :
                      <div className="mt-4">
                        <small className="text-danger">*Import Process in Working You can not add or update at the moment</small>
                        </div>:""}
                          


                        </div>
                    </div>
                </section>
                }
                </section>
           
        </div>
    )
}

export default B2b_Product_details
