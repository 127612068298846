import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { useNavigate } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';

const Master_new = () => {

    const [carriers, setCarriers] = React.useState([]); 
    console.log("carriers ===== " , carriers)
  const [selectedCarrier, setSelectedCarrier] = React.useState({carrier_name:null , carrier_id:"" , commission:0 , label_status:0 , live_customer_code:"" ,live_api_key:"", live_pricing_api_url:"", live_booking_api_url:"" , live_tracking_api_url:"" , live_label_api_url:"" , live_cancel_api_url:"" ,live_warehouse_api_url:"" , demo_customer_code:"" ,demo_api_key:"" ,demo_pricing_api_url:"", demo_booking_api_url:"" , demo_tracking_api_url:"" , demo_label_api_url:"" ,  demo_cancel_api_url:"" ,demo_warehouse_api_url:"", production_mode:false, demo_mode:false , pickup_schedule:0}); 
  console.log("selectedCarrier ===== " , selectedCarrier)
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };

  
  const fetchCarriers = () => {
   
    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/carrier_list`;
    let sendData = {  dstatus:1,
        indexValue:0,
        limit:0};
   
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        console.log("res ", res);
       
        setCarriers(res.data.output)
      
            setSelectedCarrier({...selectedCarrier,carrier_name:res.data.output[0].carrier_name, carrier_id:res.data.output[0].carrier_id , commission:res.data.output[0].commission , label_status:res.data.output[0].label_status , live_customer_code:res.data.output[0].live_customer_code ,live_api_key:res.data.output[0].live_api_key , live_pricing_api_url:res.data.output[0].live_pricing_api_url, live_booking_api_url:res.data.output[0].live_booking_api_url , live_tracking_api_url:res.data.output[0].live_tracking_api_url , live_label_api_url:res.data.output[0].live_label_api_url , live_cancel_api_url:res.data.output[0].live_cancel_api_url ,live_warehouse_api_url:res.data.output[0].live_warehouse_api_url, demo_customer_code:res.data.output[0].demo_customer_code ,demo_api_key:res.data.output[0].demo_api_key ,demo_pricing_api_url:res.data.output[0].demo_pricing_api_url, demo_booking_api_url:res.data.output[0].demo_booking_api_url , demo_tracking_api_url:res.data.output[0].demo_tracking_api_url , demo_label_api_url:res.data.output[0].demo_label_api_url ,  demo_cancel_api_url:res.data.output[0].demo_cancel_api_url ,demo_warehouse_api_url:res.data.output[0].demo_warehouse_api_url, production_mode:res.data.output[0].production_mode , demo_mode:res.data.output[0].demo_mode , pickup_schedule:res.data.output[0].pickup_schedule})
    
    }).catch((e) => {


    })
   
  }

  const handleCarrierSelection = (carrier) => {
    console.log("carrier ====== " , carrier)
    setSelectedCarrier({...selectedCarrier, carrier_name:carrier.carrier_name , carrier_id:carrier.carrier_id , commission:carrier.commission , label_status:carrier.label_status , live_customer_code:carrier.live_customer_code?carrier.live_customer_code :"" ,live_api_key:carrier.live_api_key?carrier.live_api_key:"" , live_pricing_api_url:carrier.live_pricing_api_url?carrier.live_pricing_api_url:"", live_booking_api_url:carrier.live_booking_api_url?carrier.live_booking_api_url:"" , live_tracking_api_url:carrier.live_tracking_api_url?carrier.live_tracking_api_url:"" , live_label_api_url:carrier.live_label_api_url?carrier.live_label_api_url:"" , live_cancel_api_url:carrier.live_cancel_api_url?carrier.live_cancel_api_url:"" ,
    live_warehouse_api_url:carrier.live_warehouse_api_url?carrier.live_warehouse_api_url:"" , demo_customer_code:carrier.demo_customer_code?carrier.demo_customer_code:"" ,demo_api_key:carrier.demo_api_key?carrier.demo_api_key:"" ,demo_pricing_api_url:carrier.demo_pricing_api_url?carrier.demo_pricing_api_url:"", demo_booking_api_url:carrier.demo_booking_api_url?carrier.demo_booking_api_url:"" , demo_tracking_api_url:carrier.demo_tracking_api_url?carrier.demo_tracking_api_url:"" , demo_label_api_url:carrier.demo_label_api_url?carrier.demo_label_api_url:"" ,  demo_cancel_api_url:carrier.demo_cancel_api_url?carrier.demo_cancel_api_url:"",demo_warehouse_api_url:carrier.demo_warehouse_api_url?carrier.demo_warehouse_api_url:"" ,production_mode:carrier.production_mode?carrier.production_mode:false , demo_mode:carrier.demo_mode?carrier.demo_mode:false , pickup_schedule:carrier.pickup_schedule});
  };

  const inputHandleChange = (e) => {
    console.log("e ", e.target.name);
    console.log("e ", e.target.value);
    console.log("e ", e.target.checked);

    if(e.target.type=="radio"){
        if(e.target.checked==true){
       if(e.target.name=="production_mode"){
        setSelectedCarrier({...selectedCarrier,production_mode:true , demo_mode:false}) 
       
        }
        else if(e.target.name=="demo_mode"){
              setSelectedCarrier({...selectedCarrier,demo_mode:true , production_mode:false}) 
            // setSelectedCarrier({...selectedCarrier,production_mode:false}) 
        }
        }
    }
    else{
    setSelectedCarrier({...selectedCarrier , [e.target.name]:e.target.value})
    }

}
const handleChange = (e,carrier_id) =>{
    console.log("id---> ", carrier_id);

    console.log("e---> ", e.target.name);
    console.log("e----> ", e.target.checked);
    setSelectedCarrier({...selectedCarrier, label_status:(e.target.checked)?1:0})
    changeStatus({carrier_id:carrier_id,label_status:(e.target.checked)? 1 : 0})
}
const handleChange1 = (e,carrier_id) =>{
    console.log("id---> ", carrier_id);

    console.log("e---> ", e.target.name);
    console.log("e----> ", e.target.checked);
    setSelectedCarrier({...selectedCarrier, pickup_schedule:(e.target.checked)?1:0})
    changePickupSchedule({carrier_id:carrier_id,pickup_schedule:(e.target.checked)? 1 : 0})
}
const changeStatus = (updateData) => {


    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/update_label_status_carrier`;
    let sendData = {carrier_id:updateData.carrier_id , label_status:updateData.label_status };
    console.log("sendData",sendData)
   
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        console.log("res ", res);
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          
          Toast.fire({
            background:"#206bc4",
            type: 'success',
            title: "Updated Sucessfully",
            color:"white"
          });
         
      }).catch((e) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 5000
          })
          
          Toast.fire({
            background:"#e63900",
            type: 'error',
            title: "Something Went Wrong",
            color:"white"
          });
           
           });
}
const changePickupSchedule = (updateData) => {


    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/update_pickup_schedule_carrier`;
    let sendData = {carrier_id:updateData.carrier_id , pickup_schedule:updateData.pickup_schedule };
    console.log("sendData",sendData)
   
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        console.log("res ", res);
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          
          Toast.fire({
            background:"#206bc4",
            type: 'success',
            title: "Updated Sucessfully",
            color:"white"
          });
         
      }).catch((e) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 5000
          })
          
          Toast.fire({
            background:"#e63900",
            type: 'error',
            title: "Something Went Wrong",
            color:"white"
          });
           
           });
}
const changecommission = (updateData) => {


    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/commission_update_api`;
    let sendData = selectedCarrier 
    console.log("sendData",sendData)
   
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        console.log("res ", res);
        fetchCarriers()
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          
          Toast.fire({
            background:"#206bc4",
            type: 'success',
            title: "Updated Sucessfully",
            color:"white"
          });
        
         
      }).catch((e) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 5000
          })
          
          Toast.fire({
            background:"#e63900",
            type: 'error',
            title: "Something Went Wrong",
            color:"white"
          });
           });
}
const updateCommission = () =>{
    changecommission(selectedCarrier)
}

 
  React.useEffect(() => {
    fetchCarriers();
  }, [])
  return (
    <div>
         <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>
     
      <section id="payment_gateway_info" class="container-fluid">
   
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    {/* <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="#">
                                    <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Settings</li>
                        </ol>
                    </nav> */}
                    <h2 class="h4">Master Settings</h2>
                </div>
                <div class="btn-toolbar mb-2 mb-md-0">
                    
                </div>
            </div>
            
    </section>  

    <section class="mb-3">
            
        <div class="row g-3">
        <div class="col-md-3 sticky-top custom-tab-293">
            {carriers.map((sub)=>(
                        <nav id="myTab" class="nav nav-pills flex-column custom_tab_89 text-center mb-5">
                        <a onClick={() => handleCarrierSelection(sub)} data-bs-toggle="pill" class={selectedCarrier.carrier_name==sub.carrier_name?"active nav-link":""} > <img src={`https://cdn.shipdartexpress.com/images/${sub.carrier_image}`}alt="" class="img-fluid" /><br/> <small className="text-danger">{sub.carrier_name}</small>
                        </a>
                        </nav>
            ))}
        </div>
        <div class="col-md-9 tab-content">
            <article class="tab-pane fade show active" id="category_tab1">
                <div class="card mb-3 border-0 shadow-sm">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md">
                            <div class="row">
                                <div class="col-12 col-md-4 mb-3">
                                    <label for="" class="form-label">Commission %:</label>
                                    <div class="input-group">
                                        <span class="input-group-text">@</span>
                                        <input type="number" class="form-control"  value={selectedCarrier.commission} name="commission"  onChange={(e)=>inputHandleChange(e)}/>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4 mb-3">
                                    <label for="" class="form-label">Custom Label Print:</label>
                                    <div class="form-check form-switch  mb-2">
                                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" name="label_status" checked={selectedCarrier.label_status} onChange={(e) => handleChange(e,selectedCarrier.carrier_id)} />
                                    <label class="form-check-label" for="flexSwitchCheckChecked">On/Off</label>

                                    </div>
                                </div>
                                <div class="col-12 col-md-4 mb-3">
                                    <label for="" class="form-label">Pickup Schedule:</label>
                                    <div class="form-check form-switch  mb-2">
                                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" name="pickup_schedule" checked={selectedCarrier.pickup_schedule} onChange={(e) => handleChange1(e,selectedCarrier.carrier_id)} />
                                    <label class="form-check-label" for="flexSwitchCheckChecked">On/Off</label>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-12 ">
                            <div class="alert alert-warning notes">
                                <div class="d-flex ">
                                    <div class="p-2">
                                        <i class="material-icons-outlined">lightbulb</i>
                                    </div>
                                    <div class="ps-2 ">
                                        <ul>
                                            <li>Set <strong class="fw-700">commission %</strong> and you will get x% commission on each booking made by the user on the portal. </li>
                                            <li>System will add <strong class="fw-700">% value</strong> to carrier pricing and will show the updated value to customer on booking.</li>
                                            <li><strong class="fw-700">Custom Label:</strong> <br />
                                            OFF: Show system genrated custom Label to users for download<br />
                                            ON: Show Label right from the Carrier API to users for download</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                <div class="card border-0 shadow-sm">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-12 mb-3">
                            <div>
                            <label for="" class="form-label">Operation Mode:</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="production_mode" id="inlineRadio1" value="production_mode" checked={selectedCarrier.production_mode==true} onChange={(e)=>inputHandleChange(e)}/>
                                <label class="form-check-label" for="inlineRadio1" >Live Production Mode</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="demo_mode" id="inlineRadio2" value="demo_mode"checked={selectedCarrier.demo_mode==true} onChange={(e)=>inputHandleChange(e)}/>
                                <label class="form-check-label" for="inlineRadio2">Sandbox Demo Mode</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 ">
                            <div class="card">
                            <div class="card-body bg-light-c">
                            <h5> <i class="material-icons-outlined">flash_on</i> Live Production Mode Settings</h5>
                                <div class="row">
                                    { selectedCarrier.carrier_id!="1656377050" && selectedCarrier.carrier_id!="1656677340"?
                                    <div class="col-12 col-md-12 mb-3">
                                      {selectedCarrier.carrier_id!="1656377056" && selectedCarrier.carrier_id!="1656013556" && selectedCarrier.carrier_id!="1426513056" && selectedCarrier.carrier_id!="1345673056"  ? <label for="" class="form-label">Customer Code:</label>:<label for="" class="form-label">Account Code:</label>}
                                        <input type="text" class="form-control" id="" placeholder=""  value={selectedCarrier.live_customer_code} name="live_customer_code"  onChange={(e)=>inputHandleChange(e)}  />
                                    </div>
                                    :""}
                                    {selectedCarrier.carrier_id!="1656677340"?
                                    <div class="col-12 col-md-12 mb-3">
                                       {selectedCarrier.carrier_id=="1343170006"?<label for="" class="form-label">Password:</label>: <label for="" class="form-label">API-key:</label>}
                                        <input type="text" class="form-control" id="" placeholder="Enter api-key" name="live_api_key" value={selectedCarrier.live_api_key} onChange={(e)=>inputHandleChange(e)} />
                                    </div>
                                    :""}
                                    {selectedCarrier.carrier_id!="1656377059" && selectedCarrier.carrier_id!="1656377056" && selectedCarrier.carrier_id!="1656013556" && selectedCarrier.carrier_id!="1426513056" && selectedCarrier.carrier_id!="1345673056" && selectedCarrier.carrier_id!="1343170006" ?
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="" class="form-label">Pricing API URL:</label>
                                        <input type="text" class="form-control" id="" placeholder="Enter Pricing API URL" value={selectedCarrier.live_pricing_api_url} onChange={(e)=>inputHandleChange(e)} name="live_pricing_api_url" />
                                    </div>
                                    :""}
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="" class="form-label">Booking API URL:</label>
                                        <input type="text" class="form-control" id="" placeholder="Enter Booking API URL" name="live_booking_api_url" value={selectedCarrier.live_booking_api_url} onChange={(e)=>inputHandleChange(e)} />
                                    </div>
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="" class="form-label">Tracking API URL:</label>
                                        <input type="text" class="form-control" id="" placeholder="Enter Tracking API URL" name="live_tracking_api_url" value={selectedCarrier.live_tracking_api_url} onChange={(e)=>inputHandleChange(e)}/>
                                    </div>
                                    {selectedCarrier.carrier_id!="1656377059" &&  selectedCarrier.carrier_id!="1656377056" && selectedCarrier.carrier_id!="1656013556" && selectedCarrier.carrier_id!="1426513056" && selectedCarrier.carrier_id!="1345673056" && selectedCarrier.carrier_id!="1656377050" && selectedCarrier.carrier_id!="1656677340"?
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="" class="form-label">Label API URL:</label>
                                        <input type="text" class="form-control" id="" placeholder="Enter Label API URL" name="live_label_api_url" value={selectedCarrier.live_label_api_url} onChange={(e)=>inputHandleChange(e)}/>
                                    </div>:""}

                                   
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="" class="form-label">Cancel API URL:</label>
                                        <input type="text" class="form-control" id="" placeholder="Enter Cancel API URL" name="live_cancel_api_url" value={selectedCarrier.live_cancel_api_url} onChange={(e)=>inputHandleChange(e)} />
                                    </div>
                                    {selectedCarrier.carrier_id!="1656877420" && selectedCarrier.carrier_id!="1656677340" && selectedCarrier.carrier_id!="1656377059" && selectedCarrier.carrier_id!="1343170006"?
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="" class="form-label">Warehouse API URL:</label>
                                        <input type="text" class="form-control" id="" placeholder="Enter Warehouse API URL" name="live_warehouse_api_url" value={selectedCarrier.live_warehouse_api_url} onChange={(e)=>inputHandleChange(e)} />
                                    </div>
                                    :""}
                                    
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                        <div class="card">
                            <div class="card-body bg-light-c">
                            <h5> <i class="material-icons-outlined">flash_off</i> Sandbox Demo Mode Settings</h5>
                                <div class="row">
                                { selectedCarrier.carrier_id!="1656377050" && selectedCarrier.carrier_id!="1656677340"? 
                                    <div class="col-12 col-md-12 mb-3">
                                       {selectedCarrier.carrier_id!="1656377056" && selectedCarrier.carrier_id!="1656013556" && selectedCarrier.carrier_id!="1426513056" && selectedCarrier.carrier_id!="1345673056"  ? <label for="" class="form-label">Customer Code:</label>:<label for="" class="form-label">Account Code:</label>}
                                        <input type="text" class="form-control" id="" placeholder="" name="demo_customer_code"  value={selectedCarrier.demo_customer_code} onChange={(e)=>inputHandleChange(e)}/>
                                    </div>
                                    :""}
                                    {selectedCarrier.carrier_id!="1656677340"?
                                    <div class="col-12 col-md-12 mb-3">
                                       {selectedCarrier.carrier_id=="1343170006"?<label for="" class="form-label">Password:</label>: <label for="" class="form-label">API-key:</label>}
                                        <input type="text" class="form-control" id="" placeholder="Enter api-key" name="demo_api_key" value={selectedCarrier.demo_api_key} onChange={(e)=>inputHandleChange(e)}/>
                                    </div>
                                    :""}
                                    {selectedCarrier.carrier_id!="1656377059" && selectedCarrier.carrier_id!="1656377056" && selectedCarrier.carrier_id!="1656013556" && selectedCarrier.carrier_id!="1426513056" && selectedCarrier.carrier_id!="1345673056" && selectedCarrier.carrier_id!="1343170006"?
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="" class="form-label">Pricing API URL:</label>
                                        <input type="text" class="form-control" id="" placeholder="Enter Pricing API URL" name="demo_pricing_api_url" value={selectedCarrier.demo_pricing_api_url} onChange={(e)=>inputHandleChange(e)}/>
                                    </div>
                                    :""}
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="" class="form-label">Booking API URL:</label>
                                        <input type="text" class="form-control" id="" placeholder="Enter Booking API URL" name="demo_booking_api_url" value={selectedCarrier.demo_booking_api_url} onChange={(e)=>inputHandleChange(e)} />
                                    </div>
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="" class="form-label">Tracking API URL:</label>
                                        <input type="text" class="form-control" id="" placeholder="Enter Tracking API URL" name="demo_tracking_api_url" value={selectedCarrier.demo_tracking_api_url} onChange={(e)=>inputHandleChange(e)} />
                                    </div>
                                    {selectedCarrier.carrier_id!="1656377059" &&  selectedCarrier.carrier_id!="1656377056" && selectedCarrier.carrier_id!="1656013556" && selectedCarrier.carrier_id!="1426513056" && selectedCarrier.carrier_id!="1345673056" && selectedCarrier.carrier_id!="1656377050" && selectedCarrier.carrier_id!="1656677340"?
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="" class="form-label">Label API URL:</label>
                                        <input type="text" class="form-control" id="" placeholder="Enter Label API URL" name="demo_label_api_url" value={selectedCarrier.demo_label_api_url} onChange={(e)=>inputHandleChange(e)} />
                                    </div>
                                    :""}

                            
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="" class="form-label">Cancel API URL:</label>
                                        <input type="text" class="form-control" id="" placeholder="Enter Cancel API URL" name="demo_cancel_api_url" value={selectedCarrier.demo_cancel_api_url} onChange={(e)=>inputHandleChange(e)} />
                                    </div>
                                    {selectedCarrier.carrier_id!="1656877420" && selectedCarrier.carrier_id!="1656677340" && selectedCarrier.carrier_id!="1656377059" && selectedCarrier.carrier_id!="1343170006"?
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="" class="form-label">Warehouse API URL:</label>
                                        <input type="text" class="form-control" id="" placeholder="Enter Warehouse API URL" name="demo_warehouse_api_url" value={selectedCarrier.demo_warehouse_api_url} onChange={(e)=>inputHandleChange(e)} />
                                    </div>
                                    :""}
                                  
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </article>
            <article class="tab-pane fade" id="category_tab2">
            tab 2
            </article>
            <article class="tab-pane fade" id="category_tab3">
            tab 3
            </article>
        </div>
        </div> 

        <div class="text-end mt-3">
            <button type="button" class="btn btn-primary btn-lg" onClick={updateCommission}>Update</button>
        </div>

    </section>

</section>

    </div>
  )
}

export default Master_new
