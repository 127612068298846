import React from 'react'
import Sub_header from '../Sub_header'

const Product_type = () => {
  return (
    <div>
        <Sub_header/>
        <div style={{marginLeft:"15px",marginRight:"15px"}}>
      <section>

<div class="row py-4">
    <div class="col-12 col-md">
        <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                {/* <li class="breadcrumb-item">
                    <a href="#">
                        <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                    </a>
                </li> */}
                <li class="breadcrumb-item active" aria-current="page">Product Type List</li>
            </ol>
        </nav>
        <h2 class="h4">All Product Types</h2>
    </div>
    <div class="col-12 col-md-4">
        
    </div>
    <div class="col-12 col-md text-end">
            <div class="btn-toolbar mb-2 mb-md-0">
                <div class="input-group me-2 me-lg-3 fmxw-400">
                    <span class="input-group-text">
                        <svg class="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                        </svg>
                    </span>
                    <input type="text" class="form-control" placeholder="Search Type"/>
                </div>
                <a href="#" class="btn btn-sm btn-gray-800 d-inline-flex align-items-center"  data-bs-toggle="modal" data-bs-target="#add_update_prodc_tp" data-bs-backdrop="false">
                    <svg class="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                    New Product Type
                </a>
            </div>
    </div>
</div>
<section>
<div class="modal" id="add_update_prodc_tp"  data-bs-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      
      <div class="modal-header">
        <h4 class="modal-title">Add/Update Product TYpe</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>

      <div class="modal-body">
        <div class="mb-3 mt-3">
          <label for="Product Type" class="form-label">Mode Type Use in:</label>
          <br/>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" checked />
            <label class="form-check-label" for="inlineRadio1">Domestic Mode</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
            <label class="form-check-label" for="inlineRadio2">International Mode</label>
          </div>
        </div>
        <div class="mb-3 mt-3">
          <label for="Product Type  Name" class="form-label">Product Type  Name:</label>
          <input type="text" class="form-control" id="Product Type  Name" placeholder="Enter Product Type  Name" name="Product Type  Name" required />
        </div>
        <div class="mb-3 mt-3">
          <label for="Maximum Weight( KG )" class="form-label">Maximum Weight Capacity( KG ):</label>
          <input type="number" class="form-control" id="Maximum Weight( KG )" placeholder="Enter Maximum Weight( KG )" name="Maximum Weight( KG )" required />
        </div>
        <hr/>
        <h5>Dimension Setup</h5>
        <div class="mb-3 mt-3">
          <label for="dia" class="form-label">Do You want to Set Dimension:</label>
          <div class="form-check form-switch  mb-2">
             <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
          </div>
        </div>
        <div class="mb-3 mt-3">
          <label for="Maximum Height" class="form-label">Maximum Height (CM):</label>
          <input type="number" class="form-control" id="Maximum Height" placeholder="Enter Maximum Height" name="Maximum Height" />
        </div>

        <div class="mb-3 mt-3">
          <label for="Maximum Width" class="form-label">Maximum Width (CM):</label>
          <input type="number" class="form-control" id="Maximum Width" placeholder="Enter Maximum Width" name="Maximum Width" />
        </div>
        <div class="mb-3 mt-3">
          <label for="Maximum Length" class="form-label">Maximum Length (CM):</label>
          <input type="number" class="form-control" id="Maximum Length" placeholder="Enter Maximum Length" name="Maximum Length" />
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
            <div class="col-6">
              <button type="button" class="btn btn-primary">Submit</button>
            </div>
            <div class="col-6 text-end">
              <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
      </div>

    </div>
  </div>
</div>
</section>

        <section>
            <ul class="nav nav-tabs justify-content-end">
                <li class="nav-item">
                <a class="nav-link active" href="#">All</a>
                </li>
                <li class="nav-item">
                <a class="nav-link" href="#">Domestic</a>
                </li>
                <li class="nav-item">
                <a class="nav-link" href="#">International</a>
                </li>
            </ul>
        </section>
        
        <div class="card card-body border-0 shadow table-wrapper table-responsive">
            <table class="table table-hover carr_list_ad">
                <thead style={{background:"black"}}>
                    <tr>
                        <th style={{color:"white"}}>#</th>
                        <th style={{color:"white"}}>#ID</th>	
                        <th style={{color:"white"}}>Product Type Name</th>
                        <th style={{color:"white"}}>Max Weight Cap.</th>
                        <th style={{color:"white"}}>Mode</th>
                        <th style={{color:"white"}}>Status</th>
                        <th style={{color:"white"}}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {/* <!-- Item --> */}
                    <tr>
                        <td>
                            01
                        </td>
                        <td>
                            <span class="fw-normal"><a href="">19283747</a></span>
                        </td>                        
                        <td>
                            <strong>Parcel</strong>
                        </td>                        
                        <td class="">
                            50KG
                        </td>
                        <td class="">
                            <span class="badge rounded-pill bg-primary">Domestic</span>
                        </td>
                        <td>
                            <div class="form-check form-switch  mb-2">
                               <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                            </div>
                        </td>
                        <td>
                            <div class="btn-group">
                                <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="icon icon-sm">
                                        <span class="fa fa-ellipsis-h icon-dark"></span>
                                    </span>
                                    <span class="visually-hidden">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu py-0">
                                    <a class="dropdown-item rounded-top" href="#"><span class="fa fa-eye me-2"></span>View/Update</a>
                                    <a class="dropdown-item text-danger rounded-bottom" href="#"><span class="fa  fa-trash me-2"></span>Remove</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            01
                        </td>
                        <td>
                            <span class="fw-normal"><a href="">19283747</a></span>
                        </td>                        
                        <td>
                            <strong>Document</strong>
                        </td>                        
                        <td class="">
                            0.3KG
                        </td>
                        <td class="">
                            <span class="badge rounded-pill bg-info">International</span>
                        </td>
                        <td>
                            <div class="form-check form-switch  mb-2">
                               <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                            </div>
                        </td>
                        <td>
                            <div class="btn-group">
                                <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="icon icon-sm">
                                        <span class="fa fa-ellipsis-h icon-dark"></span>
                                    </span>
                                    <span class="visually-hidden">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu py-0">
                                    <a class="dropdown-item rounded-top" href="#"><span class="fa fa-eye me-2"></span>View/Update</a>
                                    <a class="dropdown-item text-danger rounded-bottom" href="#"><span class="fa  fa-trash me-2"></span>Remove</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                                                
                </tbody>
            </table>
            <div class="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                <nav aria-label="Page navigation example">
                    <ul class="pagination mb-0">
                        <li class="page-item">
                            <a class="page-link" href="#">Previous</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">1</a>
                        </li>
                        <li class="page-item active">
                            <a class="page-link" href="#">2</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">3</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">4</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">5</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">Next</a>
                        </li>
                    </ul>
                </nav>
                <div class="fw-normal small mt-4 mt-lg-0">Showing <b>5</b> out of <b>25</b> entries</div>
            </div>
        </div> 
                

</section>
    </div>
    </div>
  )
}

export default Product_type
