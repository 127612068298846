import React from 'react'
import Left_panel from './Left_panel'
import Sub_header from './Sub_header'

const Daily_shipment_summary = () => {
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };
  return (
    <div>
     {/* <?php
            include('header.php');
        ?> */}

        {/* <?php
            include('left_nav.php');
        ?> */}
        <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
    <section class="home-section">
            {/* <?php
            include('sub_header.php');
            ?>  */}
        
    <Sub_header/>
            
        <section class="mt-3">
        <div class="row mb-3">
            <div class="col-12 col-md-4">
                <h5>Daily Shipment Summary</h5>
            </div>
            <div class="col-12 col-md">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <input type="text" class="form-control" id="text" placeholder="Select Customer"/>
                    </div>
                    <div class="col-12 col-md">
                        <input type="date" class="form-control" id="text" placeholder="select date range"/>
                    </div>
                    <div class="col-12 col-md-2 text-end">
                        <div class="d-grid">
                          <a href="" class="btn btn-primary btn-block">Get Summary</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div class="card card-body border-0 shadow table-wrapper table-responsive ">
                <table class="table table-hover  shipm_tablew">
                    <thead style={{background:"black"}}>
                        <tr>
                            <th style={{color:"white"}}>Date</th>
                            <th style={{color:"white"}}>Customer</th>
                            <th style={{color:"white"}}>Total Shipments</th>
                            <th style={{color:"white"}}>Carrier</th>
                            <th style={{color:"white"}}>Product</th>
                            <th style={{color:"white"}}>Total Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                12/08/2022 <small>12:23</small>
                            </td>  
                            <td>
                                New India Trading Company
                            </td>                      
                            <td>
                                <strong>90</strong>
                            </td>  
                            <td>
                                Delhivery
                            </td>
                            <td>
                                Delhivery Less then 1KG
                            </td>
                            <td class="fw-700">
                                $89.09
                            </td>
                        </tr>
                        <tr>
                            <td>
                                12/08/2022 <small>12:23</small>
                            </td>  
                            <td>
                                New India Trading Company
                            </td>                      
                            <td>
                                <strong>90</strong>
                            </td>  
                            <td>
                                DTDC
                            </td>
                            <td>
                                DTDC Air
                            </td>
                            <td class="fw-700">
                                $89.09
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> 
            <div class="mt-3 text-end">
                <a href="#" class="btn btn-outline-primary">Download Report</a>
            </div>

    </section>
                    

    </section>


{/* <?php
include('footer.php');
?>    */}
    </div>
  )
}

export default Daily_shipment_summary
