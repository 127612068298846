import React from 'react'
// import Customer_header from './Customer_header'
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
// import Footer from './Footer'
// import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import Sub_header from '../Sub_header';
import exportValue from '../../apiconfig'
import Left_panel from '../Left_panel';

// import config from "../config"
// import Moment from 'react-moment';
// import Header_nav from './Header_nav';
// import download from 'downloadjs'


const Summary = () => {
  let navigate = useNavigate()
  const{record_id }=useParams()
 
  const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
  const[state,setState]= React.useState([])
  console.log("state === " , state)
  const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });
  const [shipSelect, setShipSelect] = React.useState({ shipment_id: "",cancel_reason:"" });
  const [modalState, setModalState] = React.useState({ show: false });
  const [modaldtdtcState, setModaldtdcState] = React.useState({ show: false });
  const[dtdc , setdtdc] = React.useState();

  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000
})

  console.log("shipmentState",shipmentstate)
  React.useEffect(() => {

      shipment_list()
      // dimension_detail()
  
    }, [])

  const shipment_list=(status)=>{
      let dataToSend = {  record_id:record_id
  };
 
    console.log("datatoSend",dataToSend)
    // let url = exportValue.apiUrl + '/shipment/view_summary';
    let url = exportValue.host + '/' + exportValue.version + '/' + exportValue.api +'/view_summary';
   // console.log("headers => ", config.headers);
   
    
    axios.post(url, dataToSend, { headers: exportValue.headers })
        .then((res) => {
          console.log("responseJson11 => ", res);
           setState(res.data.output)
       
        })
        .catch((error) => {
            //Hide Loader
          //   setLoadingstate({...loadingstate,loading:false})
            console.log(error);
        });    

  }

  const handleRefreshButtonClick = () => {
    window.location.reload(); // Reloads the page
  };


  return (
    <div>
     <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>
        
  
  <section class="home ">
        
  {/* <div class="container"> */}

    <div class="card mb-3 mt-4">
      <div class="card-body">
        <header class="">
            <div class="row mb-4 mt-3">
                <div class="col col-md">
                    <div class="d-flex">
                        <div class="">
                            <h4><a href="/orders"><i class="material-icons">chevron_left</i></a>Summary</h4>
                        </div>
                       
                    </div>
                  
                    
                </div>
               
            </div>
            
        </header>
      </div>
    </div>

    

    <div class="card ">
      <div class="card-body">
     

        <table class="table  shipment_box table-responsive">
            <thead style={{background:"black"}}>
              <tr class="custom_row_mb">
                <th style={{color:"white"}}>order_id</th>
                <th style={{color:"white"}}> Failed Reason</th>
                <th style={{color:"white"}}>Status</th>
              </tr>
            </thead>
            <tbody>
              
                   {state.map((sub)=>(
                    sub.log.length>0?
        sub.log.map((item)=>(
            <tr class="custom_row_mb">
                        <td>
                             {item.order_id}
                        </td>
                        
                        <td>
                          {item.reason}
                        
                        </td>
                          <td>
                          {item.status==2?
                          <span className='badge bg-danger'>Failed</span>:
                          <span className='badge bg-success'>Success</span>}
                           
                        </td>

                        
                      
                        
                        </tr>
        )):<>
       
        
       <tr >
        <td colSpan="3" style={{ textAlign: 'center' }} >
          {/* Centered content */}
          <div className='mt-5'>
            <h6>Your Orders Are Still In Processing</h6>
            <br />
            <button class="btn btn-primary btn-block" onClick={() => handleRefreshButtonClick()}>Refresh</button>
          </div>
        </td>
      </tr>
        
      
        </>
      ))}
           
                       
           
               
               
            </tbody>
          </table>
      </div>
    </div>

         
  {/* </div> */}
</section>

         
  
</section>
    </div>
  )
}

export default Summary
