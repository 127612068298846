import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import ReactLoading from 'react-loading';

const Log_view = () => {
    const{product_id , carrier_id , source_city_id} = useParams()

    const[state , setState]= React.useState([])


    React.useEffect(()=>{
       categoryList()
       
    },[])
    const categoryList = () => {
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/cities_not_uploaded`;
        let sendData = { product_id: product_id, carrier_id: carrier_id , source_city_id:source_city_id};
        console.log("sendData", sendData)

        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
          setState(res.data.output)


        }).catch((e) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
            })

            Toast.fire({
                background: "#206bc4",
                type: 'unsuccess',
                title: "Something went wrong",
                color: "white"
            });

        });
    }
  return (
    <div>
         <Sub_header />
            <div style={{ marginLeft: "15px", marginRight: "15px" }}>
      <section>

<div class="row py-4">
    <div class="col-12 col-md">
        <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                <li class="breadcrumb-item">
                    <a href={`/import_process/${product_id}/${carrier_id}/${source_city_id}`}>
                        <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                    </a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Not Import Data List</li>
            </ol>
        </nav>
        <h2 class="h4">Not Imported Data list</h2>
    </div>
    <div class="col-12 col-md-5">
        
    </div>
    <div class="col-12 col-md text-end">
        
    </div>
</div>

        
        
        <div class="card card-body border-0 shadow table-wrapper table-responsive">
            <table class="table table-hover carr_list_ad">
                <thead style={{background:"black"}}>
                    <tr>
                      	
                        <th style={{color:"white"}}>City Name</th>
                        {/* <th style={{color:"white"}}>Datacount</th>
                        <th style={{color:"white"}}>Status</th>
                        <th style={{color:"white"}}>Log</th> */}
                    </tr>
                </thead>
                {state.length>0?
                state.map((sub)=>(

               
                <tbody>
                    {/* <!-- Item --> */}
                   
                    <tr>
                                           
                        <td>
                            <strong>{sub.city_name}</strong>
                        </td>                        
                        {/* <td class="">
                            {sub.uploaded_record} <small>/{sub.total_record}</small>
                        </td>
                        <td>
                            <span class="badge rounded-pill bg-primary">{sub.end_time!=undefined?"Completed":"Running"}</span>
                        </td>
                        <td>
                            <a href={`/log_view/${product_id}/${carrier_id}/${source_city_id}`} >View</a>
                        </td> */}
                    </tr>
                                                
                </tbody>
                 )):""}
            </table>
           
        </div> 
                

</section>
</div>
    </div>
  )
}

export default Log_view
