import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import axios from "axios";
import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import exportValue from '../../apiconfig'
import Barcode from 'react-barcode';



const Print_label = () => {
    const location = useLocation()
    const {shipment_id} = useParams()
    console.log("shipment_id",shipment_id)

  

    const[state,setState]= React.useState({shipment_list:[] , total:0})
    console.log("state",state)
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})


    React.useEffect(() => {
      dimension_detail()
      shipment_detail()
    
      }, [])
   

      const shipment_detail=()=>{
        let dataToSend = {shipment_id:shipment_id };
   
      console.log("datatoSend",dataToSend)
      let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_detail`;
     
     // console.log("headers => ", config.headers);
     
      
      axios.post(full_api, dataToSend, { headers: exportValue.headers })
          .then((res) => {
            console.log("responseJson => ", res);
            setState({shipment_list:res.data.output , total:res.data.total})
          
  
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              console.log(error);
          });    

    }
    const dimension_detail = () =>{

      let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/company_info_api`;
      let sendData = {};
    
      axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
        setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
        console.log("rnmmmmm", res);
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        console.log("----error:   ", e);
      })
    }
 const  FunctionComponent = () => {
    return <Barcode value={shipment_id} />;
};
  return (
    <div>
         {state.shipment_list.map((sub)=>(
      <section class="mb-3 pt-5 bg-light p-5  ">
   <div class="lable">
   <div class="card border-dark">
     <div class="card-body">
      <div class="d-flex justify-content-between border-oring">
     
          <div class="">
            <h4>TO</h4>
            <h3>{sub.sender.name}</h3>
            <h4>{sub.sender.address}</h4>
            <p>{sub.sender.city}, {sub.sender.state}, {sub.sender.country} - {sub.sender.postal_code}</p>
          </div>
      
      
          <div class=" ">
            <h6>Origin</h6>
            <h1>{sub.sender.city.substring(0,3).toUpperCase()}</h1>
            <small>Contact no. {sub.sender.mobile}</small>
          </div>
     
      </div>
      <div class="d-flex justify-content-between pt-2">
     
          <div class="">
            <h4>FROM</h4>
            <h5>{sub.receiver.name}</h5>
            <p>{sub.receiver.address}</p>
            <p>{sub.receiver.city}, {sub.receiver.state}, {sub.receiver.country} - {sub.receiver.postal_code}</p>
          </div>
     
     
          <div class=" ">
            <h6>Destination</h6>
            <h1>{sub.receiver.city.substring(0,3).toUpperCase()}</h1>
            <small>Contact no. {sub.receiver.mobile}</small>
          </div>
      
      </div>
      <hr/>
      <div class="d-flex justify-content-between">
          <div class="">
            <p>Order Date: <strong> <Moment format="MMM DD YYYY">
                        {new Date(sub.created*1000)}
            </Moment> <br/>
                            <small><Moment format="hh:mm">
                        {new Date(sub.created*1000)}
            </Moment></small></strong></p>
            <p>Invoice #: <strong>{sub.order_number}</strong></p>
          </div>
          <div class=" ">
          <FunctionComponent/><br/>
          
          </div>
      </div>
      <hr/>
      <div class="d-flex justify-content-between">
          <div class="">
            <h1>{sub.payment_mode.toUpperCase()}</h1>
            {sub.payment_mode=="cod"?<h6>COD AMOUNT: {sub.cod_amount}</h6>:""}
            <small>Total Weight: {sub.total_weight!=undefined && sub.total_weight!=""?sub.total_weight:""}({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_weight}</>
                                 ))})</small>
          </div>
          <div class=" ">
            <small>{sub.service_provider}</small><br/>
            <img src="https://i.imgur.com/I5kPgVW.png" class="img-fluid qrcode" /> <img src="https://i.imgur.com/3saCi40.png" class="img-fluid barcode" /><br/>
            <small>AWB #: {sub.shipment_id}</small><br/>
            {sub.items.map((item)=>(
            <small>Dim: ({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_dimension}</>
                                 ))}): H {item.item_height} x W {item.item_width} x L {item.item_length}</small>
            ))}
          </div>
      </div>
      <hr/>
      <table class="table table-bordered">
          <thead style={{background:"black"}}>
            <tr>
              <th style={{color:"white"}}>ITEM NAME</th>
              <th style={{color:"white"}}>QTY</th>
              <th style={{color:"white"}}>AMOUNT</th>
            </tr>
          </thead>
          
          <tbody>
          {sub.items.map((item)=>(
            <tr>
              <td>{item.item_name}</td>
              <td>{item.item_quantity}</td>
              <td>{dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}{item.item_value}</td>
            </tr>
               ))}
            <tr>
              <td></td>
              <td>Order Total</td>
              <td>{dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}{state.total}</td>
            </tr>
          </tbody>
       
        </table>
        <small>This is computer generated document,hence does not required signature</small>
     </div>
   </div>
   </div>
 </section>
         ))}
    </div>
  )
}

export default Print_label
