import React, { useState, useEffect } from "react";
import axios from "axios";
import Moment from 'react-moment';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import TablePagination from '@mui/material/TablePagination';
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel'
import Sub_header from '../Sub_header'
import '../../components/loader.css';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'




const Agents = () => {
    let navigate = useNavigate();
    const search1 = window.location.search;
    const params = new URLSearchParams(search1);
    let search= params.get('search')
    const [state,setState] = useState({isLoading:true,user_list:[]});
    const [otherStates,setOtherState] = useState({dstatus:"",activePage:1,rowsPerPage:10,page:0,total_count:0,onload:true});
    const [modalState,setModalState] = useState({show:false,user_id:"",action:""});
const [searchfilters, setsearchFilters] = React.useState({ searchtitle: "" })

    useEffect(() => {
        getUsersList(0,true);
    },[])
    
    useEffect(() => {
        // setOtherState({...otherStates,searchKeyword:""}) 
        getUsersList(0,true);
    },[otherStates.dstatus]);

    const getUsersList = (index=0,onLoad) => {
        
        
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_list`;
        let sendData = {find:"",dstatus:otherStates.dstatus,limit:otherStates.rowsPerPage,indexValue:index};
       
        if(search!=null && search.length>0){
            sendData.find= search
        }
        console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {

            console.log("response ", res);
            console.log("otherStates ", otherStates);
            if(index == 0 && onLoad) {
                        setOtherState({...otherStates,total_count:res.data.count})         

            }
                        setState({...state,user_list:res.data.output,isLoading:false})
           
            

        }).catch((e) => {


        });
    }

    const usersAction = () => {
        
        
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_list`;
        let sendData = {dstatus:otherStates.dstatus};
       // console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
           // console.log("res ", res);
           
            setState(res.data.output)
           
            

        }).catch((e) => {


        });
    }

 
    // const searchUser = (e) =>{
    //      setOtherState({...otherStates,searchKeyword:e.target.value})
    //     getUsersList(0,e.target.value,true);
    //  }
    // const modalClose = (value) => {
    //     setModalState({ show: false });
    //     console.log("value ",value);
    // }
    function deleteship(user_id,dstatus) {
        let userdata = {user_id:user_id,dstatus:dstatus}
        axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user_remove` , userdata ,{ headers: exportValue.headers }).then((res)=>{
          
         setState({...state,user_id:user_id});
         getUsersList(0,true);
        //  setModals({...modals, show:false})
         console.log("res",res)
        
         if(res.data.status==200){
            Swal.fire(
                'Good job!',
                'USer is Deleted Successfully !',
                'success'
              )
         }
         else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
         }
         }).catch((e) => {
   
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
           
         })
     }
     function suspend(user_id,dstatus) {
        let userdata = {user_id:user_id,dstatus:dstatus}
        axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user_remove` , userdata ,{ headers: exportValue.headers }).then((res)=>{
          
         setState({...state,user_id:user_id});
         getUsersList(0,true);
        //  setModals({...modals, show:false})
         console.log("res",res)
        
         if(res.data.status==200){
            Swal.fire(
                'Good job!',
                'User is Suspended Successfully !',
                'success'
              )
         }
         else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
         }
         }).catch((e) => {
   
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
           
         })
     }
     function activeInactive(user_id,dstatus) {
        let userdata = {user_id:user_id,dstatus:dstatus}
        axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user_remove` , userdata ,{ headers: exportValue.headers }).then((res)=>{
          
         setState({...state,user_id:user_id});
         getUsersList(0,true);
        //  setModals({...modals, show:false})
         console.log("res",res)
        
         if(res.data.status==200){
            Swal.fire(
                'Good job!',
                'User status changed Successfully !',
                'success'
              )
         }
         else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
         }
         }).catch((e) => {
   
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
           
         })
     }
    
  
   
   
    const searchUser = ( index=0) => {
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/user_find_detail`;
            
        let transporter_data= { indexValue:index, limit:otherStates.rowsPerPage, dstatus:1,full_name:""};
        // console.log("pv",state.title)
        if ( searchfilters.searchtitle) {
           transporter_data.full_name= searchfilters.searchtitle;
         }
         console.log("transporter",transporter_data)
       
    axios.post(full_api,transporter_data, { headers: exportValue.headers }).then(res => {        
//    setState({ ...state,searchValue:res.data.title})
   console.log( "response123",res);
   if(index==0 && otherStates.onload) {
    setOtherState({...otherStates,total_count:res.data.dataCount,onload:false})         
}  
   setState({ ...state, user_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
       
   
   

   }).catch((e) => {
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        
      })
       console.log("----error:   ", e);
   })          
  }



React.useEffect(()=>{
    if (search) {
        setsearchFilters({...searchfilters,searchtitle:search})
    }
// searchUser(0)
// axios_get_api(null,0,true);


},[])

  return (
    <div>
         <Sub_header/>
         <div style={{marginLeft:"15px",marginRight:"15px"}}>
         <section>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="#">
                                    <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Users</li>
                        </ol>
                    </nav>
                    <h2 class="h4">All Agents</h2>
                </div>
                <div class="btn-toolbar mb-2 mb-md-0">
                    <div class="input-group me-2 me-lg-3 fmxw-400">
                        <span class="input-group-text">
                            <svg class="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                            </svg>
                        </span>
                        <input type="text" class="form-control" placeholder="Search users"/>
                    </div>
                </div>
            </div>
            
            <section>
                <ul class="nav nav-tabs justify-content-end">
                    <li class="nav-item">
                      <a class="nav-link active" href="#">All</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">Active</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">Inactive</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">Deleted</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">Suspeded</a>
                    </li>
                </ul>
            </section>
            <div class="card card-body border-0 shadow table-wrapper table-responsive">
                <table class="table table-hover">
                    <thead style={{background:"black"}}>
                        <tr>
                            <th style={{color:"white"}}>#</th>
                            <th style={{color:"white"}}>Date</th>
                            <th style={{color:"white"}}>Name</th>						
                            <th style={{color:"white"}}>Email</th>
                            <th style={{color:"white"}}>Mobile #</th>
                            <th style={{color:"white"}}>Type</th>
                            <th style={{color:"white"}}>Status</th>
                            <th style={{color:"white"}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <!-- Item --> */}
                        <tr>
                            <td>
                                01
                            </td>
                            <td>
                                <span class="fw-normal">12/08/2022 <small>12:23</small></span>
                            </td>
                            <td>
                                <a href="/user_detail/234234" class="fw-bold text-info">
                                    Mukesh Kumar 
                                </a>
                            </td>                        
                            <td><span class="fw-normal">mukesh@gmail.com</span></td>
                            <td><span class="fw-bold">+919239238233</span></td>
                            <td><span class="badge rounded-pill bg-info">Staff</span></td>
                            <td><span class="badge rounded-pill bg-success">Active</span></td>
                            <td>
                                <div class="btn-group">
                                    <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span class="icon icon-sm">
                                            <span class="fa fa-ellipsis-h icon-dark"></span>
                                        </span>
                                        <span class="visually-hidden">Toggle Dropdown</span>
                                    </button>
                                    <div class="dropdown-menu py-0">
                                        <a class="dropdown-item rounded-top" href="#"><span class="fa fa-eye me-2"></span>View/Update</a>
                                        <a class="dropdown-item rounded-top" href="#"><span class="fa fa-eye-slash me-2"></span>Inactive User</a>
                                        <a class="dropdown-item text-danger rounded-top" href="#"><span class="fa fa-ban me-2"></span>Suspend User</a>
                                        <a class="dropdown-item text-danger rounded-bottom" href="#"><span class="fa  fa-trash me-2"></span>Remove</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                01
                            </td>
                            <td>
                                <span class="fw-normal">12/08/2022 <small>12:23</small></span>
                            </td>
                            <td>
                                <a href="user_info.php" class="fw-bold text-info">
                                    Mukesh Kumar 
                                </a>
                            </td>                        
                            <td><span class="fw-normal">mukesh@gmail.com</span></td>
                            <td><span class="fw-bold">+919239238233</span></td>
                            <td><span class="badge rounded-pill bg-info">Staff</span></td>
                            <td><span class="badge rounded-pill bg-success">Active</span></td>
                            <td>
                                <div class="btn-group">
                                    <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span class="icon icon-sm">
                                            <span class="fa fa-ellipsis-h icon-dark"></span>
                                        </span>
                                        <span class="visually-hidden">Toggle Dropdown</span>
                                    </button>
                                    <div class="dropdown-menu py-0">
                                        <a class="dropdown-item rounded-top" href="#"><span class="fa fa-eye me-2"></span>View/Update</a>
                                        <a class="dropdown-item rounded-top" href="#"><span class="fa fa-eye-slash me-2"></span>Inactive User</a>
                                        <a class="dropdown-item text-danger rounded-top" href="#"><span class="fa fa-ban me-2"></span>Suspend User</a>
                                        <a class="dropdown-item text-danger rounded-bottom" href="#"><span class="fa  fa-trash me-2"></span>Remove</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                                                    
                    </tbody>
                </table>
                <div class="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination mb-0">
                            <li class="page-item">
                                <a class="page-link" href="#">Previous</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">1</a>
                            </li>
                            <li class="page-item active">
                                <a class="page-link" href="#">2</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">3</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">4</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">5</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>
                    <div class="fw-normal small mt-4 mt-lg-0">Showing <b>5</b> out of <b>25</b> entries</div>
                </div>
            </div> 
                    

    </section>
         </div>
    </div>
  )
}

export default Agents