import React from 'react'
import Left_panel from '../Left_panel'
import Sub_header from '../Sub_header'
import axios from 'axios'
import exportValue from '../../apiconfig'
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import '../../components/loader.css';
import TablePagination from '@mui/material/TablePagination';
import { Container, Row, Col, Form, Button, ProgressBar, ModalFooter } from "react-bootstrap"



const Tickets = () => {

  const [state, setState] = React.useState({
    ticket_list:[],
    dataCount: 0,
    isLoading: true,
    dialogOpen: false,
    searchValue: "",
   

})
const[searchstate , setSearchstate] = React.useState({search:""})
const [shipmentstate, setshipStatus] = React.useState({ status: null });
const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:10,page:0,total_count:0,onload:true});
const[editmodals,setEditModals] = React.useState({show: false, ticket_id:"" ,subject:"",ticket_createdById:"",shipment_id:"",date:"",time:"",details:"",full_name:"",ticket_branch_name:"",reply_detail:[],transporter_id:"",user_id:"", full_name_transporter:""})
const [reply, setReply] = React.useState({  reply_content:"",reply_id:"",ticket_id:"" })
const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
const [searchfilters, setsearchFilters] = React.useState({ filterStatus:[]})
  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };
let admindata = {};
if (localStorage.getItem('admin_login')) {
  admindata = JSON.parse(localStorage.getItem('admin_login'));
  console.log("admindata------>  -  ",admindata);
  //navigate('/home') 
}
let admin = admindata.adminid
console.log("admin_id",admin)
const axios_get_api = (status,index=0,onLoad) => {
     console.log("dstatus--->",status)
  
    let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/ticket_list`;
    let sendData = {
       
       
        
        indexValue: index,
        limit:otherStates.rowsPerPage
    };
    if(searchstate.search!=""){
        sendData.find = searchstate.search
    }
    if(searchfilters.filterStatus.length>0){
        sendData.status = searchfilters.filterStatus
    }
     console.log("send",sendData)
    axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
        console.log("index",index)
        console.log("onLoad",onLoad)
        console.log( "response",res);
// if(res.data.ouptut.length>0){
        if(index==0  && onLoad) {
            setOtherState({...otherStates,total_count:res.data.dataCount})         
}
       
     setState({ ...state, ticket_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
     if(res.data.output.length>0){
     let sub = res.data.output[0]
     setEditModals({ticket_id:sub.ticket_id, ticket_createdById : sub.ticket_createdById ,subject:sub.subject, shipment_id:sub.shipment_id,details:sub.details,full_name:sub.full_name,status:sub.status,date:sub.date,time:sub.time,reply_detail:sub.reply, customer_id:sub.customer_id})
     }
     else{
        setEditModals({ticket_id:"", ticket_createdById : "" ,subject:"", shipment_id:"",details:"",full_name:"",status:"",date:"",time:"",reply_detail:[], customer_id:""})
     }
      
      
// }
// else{
//     setEditModals({ticket_id:"", ticket_createdById : "" ,subject:"", shipment_id:"",details:"",full_name:"",status:"",date:"",time:"",reply_detail:[], customer_id:""})

// }


    }).catch((e) => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            
          })
        console.log("----error:   ", e);
    })

}

React.useEffect(() => {
  axios_get_api(null,0,true)
}, [])
React.useEffect(() => {
    axios_get_api(null,0,true)
  }, [searchfilters])

const shipmentFilter = (status) => {
  console.log("action ",status);
  setshipStatus({ ...shipmentstate, status: status })

  axios_get_api(status,0,true)
  
}

const handlePageChange = (event,newPage) =>{
  console.log("newpage",newPage)
  setOtherState({...otherStates,page:newPage})
  //console.log("newPage ", newPage);
  axios_get_api(shipmentstate.status,newPage);
  
  // searchUser(state.searchValue,newPage)
}
const handleChangeRowsPerPage = (event,newPage) => {
  console.log("event ", event);
  setOtherState({...otherStates, rowsPerPage:+event.target.value, page:0})
 
}

const nameFormat = (name)=> {
        console.log("name === " , name)
  if(name) {
      let nameArray =  name.split(" ");
     // console.log("nameArray  ",nameArray);
      if(nameArray.length > 1) {
          let n1 = nameArray[0].substring(0,1).toUpperCase();
          let n2 = nameArray[1].substring(0,1).toUpperCase();
          name = n1+n2;
      }else {
          name = nameArray[0].substring(0,2).toUpperCase();
          
      }
  }else{
      name = "NO";
  }
  return name;
}

const handleChange = (event) => {
  console.log(event);  
  setReply({
      ...reply,
      [event.target.name]: event.target.value,
    });
  
}

const handleSupportReply = (ticket_id) => {
  console.log("ticket_id",ticket_id)
    
  console.log("submit1");
 
  let state_detailes = {reply_content:reply.reply_content,reply_id:"", ticket_id:editmodals.ticket_id}
 
  if(admindata.adminid) {
    state_detailes.reply_id = admindata.adminid;
  }
 

  console.log("report detailes is ",state_detailes);
 
  axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/add_reply`,  state_detailes , { headers: exportValue.headers }).then((result)=>{
          console.log("result is i ",result);
         
          if(result && result.data != undefined){
             
            Swal.fire(
              'Good job!',
              ' Reply Sent  Successfully !',
              'success'
            )
               setEditModals({...editmodals,show:false})
               setReply({...reply,reply_content:""})
              axios_get_api()
                  
              }
              else{
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong!',
                  
                })
              }

       }).catch((e) => {
     

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          
        })
        console.log("----error:   ", e);
      });
}
const closeTicket = (ticket_id) => {

  let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/closeTicket`;
  let sendData = {ticket_id:ticket_id};
  // console.log("bb", sendData);
  axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      console.log("res ", res);
      Swal.fire(
          'Success!',
          'Ticket Successfully Closed!',
          'success'
        )
        setEditModals({...editmodals,show:false})
       axios_get_api()
    //  setState(res.data.output)
     // setOtherState({ ...otherStates, total_count: res.data.count });
  }).catch((e) => {
      Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          
        })
   
    console.log("----error:   ", e);

  });

}
const deleteConfirm = (ticket_id) => {
  Swal.fire({
      title: 'Are you sure?',
      text: "You want to close this ticket!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, close it!'
  }).then((result) => {
      //console.log("result  ",result);
      if(result.isConfirmed) {
          closeTicket(ticket_id)
      }       
  })

}
    
const setDetail = (sub)=>{
    setEditModals({ticket_id:sub.ticket_id, ticket_createdById : sub.ticket_createdById ,subject:sub.subject, shipment_id:sub.shipment_id,details:sub.details,full_name:sub.full_name,status:sub.status,date:sub.date,time:sub.time,reply_detail:sub.reply, customer_id:sub.customer_id})
}

const searchChange =(e)=>{
    setSearchstate({...searchstate,search:e.target.value})
}

const filter=(e,values)=>{
    const name = e.target.name;
    const value = values;
    const isChecked = e.target.checked;
  
    // Create a new array by spreading the existing filterStatus array
    const updatedFilterStatus = [...searchfilters.filterStatus];
  
    if (isChecked) {
      updatedFilterStatus.push(value);
    } else {
      // Remove the value from the array if unchecked
      const index = updatedFilterStatus.indexOf(value);
      if (index !== -1) {
        updatedFilterStatus.splice(index, 1);
      }
    }
  
    // Update the searchfilters state with the updated filterStatus array
    setsearchFilters((prevFilters) => ({
      ...prevFilters,
      filterStatus: updatedFilterStatus,
    }));
  
    
    
  }
  return (
//     <div>
//         <Sub_header/>
//         <div style={{marginLeft:"15px",marginRight:"15px"}}>
           

//                 <section>
//     <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
//                 <div class="d-block mb-4 mb-md-0">
//                     <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
//                         <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
//                             <li class="breadcrumb-item">
//                                 <a href="#">
//                                     <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
//                                 </a>
//                             </li>
//                             <li class="breadcrumb-item active" aria-current="page">Tickets</li>
//                         </ol>
//                     </nav>
//                     <h2 class="h4">All Tickets</h2>
//                 </div>
//                 <div class="btn-toolbar mb-2 mb-md-0">
                    
//                 </div>
//             </div>
//             <section>
//                 <ul class="nav nav-tabs justify-content-end">
//                     <li class="nav-item">
//                       <a class={(shipmentstate.status == null) ? 'nav-link active' : 'nav-link'} onClick={() => shipmentFilter(null)}>All</a>
//                     </li>
//                     <li class="nav-item">
//                       <a class={(shipmentstate.status == 0) ? 'nav-link active' : 'nav-link'} onClick={() => shipmentFilter(0)}>Pending</a>
//                     </li>
//                     <li class="nav-item">
//                       <a class={(shipmentstate.status == 1) ? 'nav-link active' : 'nav-link'} onClick={() => shipmentFilter(1)}>Answered</a>
//                     </li>
//                     <li class="nav-item">
//                       <a class={(shipmentstate.status == 2) ? 'nav-link active' : 'nav-link'} onClick={() => shipmentFilter(2)}>Closed</a>
//                     </li>
//                 </ul>
//             </section>
//             <div class="card card-body border-0 shadow table-wrapper table-responsive">
//                 <table class="table table-hover">
//                     <thead style={{background:"black"}}>
//                         <tr>
//                             <th style={{color:"white"}}>Date</th>
//                             <th style={{color:"white"}}>Ticket ID</th>						
//                             <th style={{color:"white"}}>Shipment #</th>						
                          			
//                             <th style={{color:"white"}}>Customer</th>
//                             <th style={{color:"white"}}>Status</th>
//                         </tr>
//                     </thead>
//                    {state.ticket_list.map((sub)=>( 
                      
//                     <tbody>
                      
//                         <tr>
//                             <td>
//                                 <span class="fw-normal">
//                                  {sub.date} {sub.time}
//                                 </span>
//                             </td>
//                             <td>
//                                <a 
//                                 onClick={()=>setEditModals({show:true , ticket_id:sub.ticket_id, ticket_createdById : sub.ticket_createdById ,subject:sub.subject, shipment_id:sub.shipment_id,details:sub.details,full_name:sub.full_name,status:sub.status,date:sub.date,time:sub.time,reply_detail:sub.reply, customer_id:sub.customer_id  })}
//                                 class="fw-700 text-info">
//                                    {sub.ticket_id} 
//                                   </a>
//                             </td>
//                             <td>
//                                 <a  class="fw-700 text-info">
//                                    {sub.shipment_id!="" && sub.shipment_id!=undefined? sub.shipment_id:""} 
//                                   </a>
//                             </td>
                         
//                             <td>
//                                 <a  class="fw-700 text-info">
//                                     {sub.full_name!="" && sub.full_name!=undefined? sub.full_name:""}
//                                 </a>
//                             </td>  
//                             <td>
//                                <span class={sub.status==0?"badge rounded-pill bg-warning":sub.status==1?"badge rounded-pill bg-success":sub.status==2?"badge rounded-pill bg-danger":""}>{sub.status==0?"Pending":sub.status==1?"Answered":sub.status==2?"Closed":""}</span> 
//                               </td>
//                         </tr>
                                                    
//                     </tbody>
//                       ))} 
//                 </table>
//                 <TablePagination
//                 component="div"
//                 rowsPerPageOptions={[5,10]}
//                 count={otherStates.total_count}
//                 page={otherStates.page}
//                 onPageChange={handlePageChange}
//                 rowsPerPage={otherStates.rowsPerPage}
//                 onRowsPerPageChange={handleChangeRowsPerPage}
               
//                         />
               
//             </div> 
                    

//     </section>


   

//             </div>

       
//         <section>
      
//         <Modal show={editmodals.show} onHide={()=>setEditModals({...editmodals,show:false})} size="lg">
        
//                 <div class="modal-content">
//                     <div class="modal-header">
//                         <h2 class="h6 modal-title">Ticket ID #{editmodals.ticket_id}<span class="badge rounded-pill bg-danger">{editmodals.status==0?"Pending":editmodals.status==1?"Answered":"Closed"}</span></h2>
                        
//                         <button type="button" class="btn-close" onClick={()=>setEditModals({...editmodals,show:false})}></button>
//                     </div>
//                     <div class="modal-body">
//                         <section class="bg-light ">
//                           <div className='row'>
//                             <div className='col-6'>  <h3>{editmodals.subject}</h3></div>
//                             <div className='col-6'>
//                               {editmodals.status!=2?
//                               <p className='text-end text-dark' style={{marginTop:"-1px",cursor:"pointer"}} onClick={()=>deleteConfirm(editmodals.ticket_id)}>Close Ticket</p>
//                               :""}
//                             </div>

//                           </div>
                          
//                             <p>
//                                 <small>{editmodals.time} {editmodals.date} | {editmodals.shipment_id!=""?"Shipment #":""}<a >{editmodals.shipment_id!=""?editmodals.shipment_id:""}</a> | {editmodals.full_name!=""?"Customer #":""}<a href="">{editmodals.full_name!=""?(editmodals.ticket_createdById):""}</a>
//                                 </small>
//                             </p>
//                         </section>
            
//                         <hr/>
//                         {editmodals.reply_detail.map((item)=>(
//                         <section>
//                         {item.reply_id==editmodals.customer_id?
//                             <div class="card shadow-sm mb-3">
//                               <div class="card-body">
//                                 <div class="d-flex p-3 ">
//                                   <div class="p-2">
//                                     <img src={"https://dummyimage.com/50/1976d2/ffffff&text="+nameFormat(editmodals.full_name)}class="img-fluid rounded-circle" style={{minWidth: "50px"}} />
//                                   </div>
                                  
//                                   <div class="p-2">
//                                   <p>{item.reply_content}</p>
//                                     <div class="">
//                                     <small class="text-muted"><a >{editmodals.full_name}</a> (Customer) </small>
//                                   <br/>
//                                     <small class="text-muted">{item.date} {item.time}</small>
//                                     </div>
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                             : item.reply_id==editmodals.transporter_id?
//                             <div class="card shadow-sm mb-3">
//                               <div class="card-body">
//                                 <div class="d-flex p-3 ">
//                                   <div class="p-2">
//                                     <img src={"https://dummyimage.com/50/1976d2/ffffff&text="+nameFormat(editmodals.full_name_transporter)} class="img-fluid rounded-circle" style={{minWidth: "50px"}} />
//                                   </div>
//                                   <div class="p-2">
//                                     <p>{item.reply_content}</p>
//                                     <div class="text-end">
//                                       <small class="text-muted"><a >{editmodals.full_name_transporter} ({editmodals.ticket_branch_name})</a> (Transporter) </small><br/>
//                                       <small class="text-muted">{item.date} {item.time}</small>
//                                     </div>
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                            : item.reply_id!=editmodals.customer_id?
//                             <div class="card shadow-sm mb-3">
//                               <div class="card-body">
//                                 <div class="d-flex p-3 ">
//                                   <div class="p-2">
//                                     <p>{item.reply_content}</p>
//                                     <div class="text-end">
//                                       <small class="text-muted"><a >{admindata.full_name} </a> (System Admin) </small><br/>
//                                       <small class="text-muted">{item.date} {item.time}</small>
//                                     </div>
//                                   </div>
//                                   <div class="p-2">
//                                     <img src={"https://dummyimage.com/50/63a4ff/ffffff&text="+nameFormat(admindata.full_name)} class="img-fluid rounded-circle" style={{minWidth: "50px"}} />
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                             :""} 
//                         </section>
//                            ))}
            
//             {editmodals.status!=2 ?
//                         <section class="bg-light p-5">
//                           <label for="comment">Reply:</label>
//                           <textarea class="form-control" rows="5" id="comment" name="reply_content" placeholder="Please enter details" onChange={(e) => handleChange(e)} value={reply.reply_content}></textarea>
//                           <button type="button" class="btn btn-primary mt-3" onClick={()=>handleSupportReply({ticket_id:editmodals.ticket_id})}>Reply</button>
//                         </section>
// :""}
//                     </div>
//                     <ModalFooter>
                   
//                         <button type="button" class="btn btn-link text-white ms-auto bg-black" onClick={()=>setEditModals({...editmodals,show:false})}>Close</button>
                   
//                     </ModalFooter>
                    
//                 </div>
            
//         </Modal>
     
//     </section>
//     </div>
<div>
{/* <?php
            include('header.php');
        ?>

        <?php
            include('left_nav.php');
        ?> */}
       <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    <section class="home-section">
            {/* <?php
            include('sub_header.php');
            ?> 
         */}
    <Sub_header/>
            
             <div class="row mt-1 g-2 support_list_lft">
                <div class="col-12 col-md-4 ">
                    <div class="card_ticket_list vh-100-min">
                        <div class="">
                        <h5>Recent Tickets</h5>
                        <div class="row mb-4">
                            <div class="col-12 col-md">
                                <div class="search-box">
                                    <div class="search-icon">
                                        <i class="fa fa-search" onClick={(e)=>axios_get_api(null,0,true)}></i> 
                                    </div>
                                    <input type="text" class="search-input" placeholder="Search ticket..." name="searchValue" onChange={(e)=>searchChange(e)}/>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="btn-group">
                                    <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"><i class='bx bx-filter' ></i> &nbsp;
                                        Filter <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    </button>
                                    <div class="dropdown-menu">
                                        <section class="p-2">
                                        <hr/>
                                            
                                        <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" onChange={(e)=>filter(e,1)}/>
                                                <label class="form-check-label">Answered</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" onChange={(e)=>filter(e,0)}/>
                                                <label class="form-check-label">Awaiting Response</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" onChange={(e)=>filter(e,2)}/>
                                                <label class="form-check-label">Closed</label>
                                            </div>
                                        </section>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ticket_list">
                            <div class="list-group">
                            {state.ticket_list.map((sub)=>( 
                                <a  onClick={()=>setDetail(sub)} class={editmodals.ticket_id==sub.ticket_id?"list-group-item list-group-item-action active_list":"list-group-item list-group-item-action"}>
                                    <div class="row">
                                        <div class="col-12 col-md">
                                            <h1>{sub.subject}</h1>
                                            <h2>Shipment # {sub.shipment_id!="" && sub.shipment_id!=undefined? sub.shipment_id:""} </h2>
                                        </div>
                                        <div class="col-12 col-md-6 text-end">
                                            <div>
                                                <small class="text-muted"> {sub.date} {sub.time}</small>
                                            </div>
                                            {sub.status==0? <span class="badge_t status_p">
                                                <i class="fa fa-circle" aria-hidden="true"></i> Awating response
                                            </span>:sub.status==1?  <span class="badge_t status_o">
                                                <i class="fa fa-circle" aria-hidden="true"></i> Answered
                                            </span>:sub.status==2? <span class="badge_t status_c">
                                                <i class="fa fa-circle" aria-hidden="true"></i> Closed
                                            </span>:""}
                                           
                                        </div>
                                    </div>
                                    {/* <p>
                                        Hi, I need your help to process the payment
                                    </p>
                                    <div>
                                        <h4>Assigned to: Mike Smith</h4>
                                    </div> */}
                                </a>
                            ))}
                              
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md ticket_list_md ticket_list vh-100-min">
                    <div class="row mb-4">
                        <div class="col-12 col-md">
                            <h3>{editmodals.subject} #{editmodals.ticket_id}</h3>
                            <div class="row">
                                <div class="col-12 col-md">
                                    <small>{editmodals.date} {editmodals.time} <i class="fa fa-circle" aria-hidden="true"></i> #{editmodals.shipment_id!=""?"Shipment #":""}<a >{editmodals.shipment_id!=""?editmodals.shipment_id:""}</a> | {editmodals.full_name!=""?"Customer #":""}<a href="">{editmodals.full_name!=""?(editmodals.ticket_createdById):""}</a></small>
                                </div>
                                {/* <div class="col-12 col-md-3 text-end">
                                    <select class="form-select form-select-sm">
                                        <option>Assign to</option>
                                        <option>Mark Smith</option>
                                        <option>Danial Star</option>
                                        <option>John Doe</option>
                                      </select>
                                </div> */}
                            </div>
                            
                            <div class="mb-3 mt-3">
                                
                            </div>
                        </div>
                        <div class="col-12 col-md-4 text-end">
                        {editmodals.status==0? <span class="badge_t status_p">
                                                <i class="fa fa-circle" aria-hidden="true"></i> Awating response
                                            </span>:editmodals.status==1?  <span class="badge_t status_o">
                                                <i class="fa fa-circle" aria-hidden="true"></i> Answered
                                            </span>:editmodals.status==2? <span class="badge_t status_c">
                                                <i class="fa fa-circle" aria-hidden="true"></i> Closed
                                            </span>:""}
                                            {editmodals.status!=2?
                            <div class="mt-2">
                                <a onClick={()=>deleteConfirm(editmodals.ticket_id)} class="btn btn-danger btn-sm">Mark as Closed</a>
                            </div>
                            :""}
                           
                        </div>
                    </div>
                    

                    <div class="card">
                    {editmodals.reply_detail.map((item)=>(
                        <div class="card-body">
                        
                             {item.reply_id==editmodals.customer_id?
                            <div class="row">
                                <div class="col-12 col-md-1">
                                    <img src={"https://dummyimage.com/50/1976d2/ffffff&text="+nameFormat(editmodals.full_name)} class="img-fluid" />
                                </div>
                                <div class="col-12 col-md">
                                    <div class="row">
                                        <div class="col-12 col-md">
                                            <h3>{editmodals.full_name} (Customer)</h3>
                                        </div>
                                        <div class="col-12 col-md-4 text-end">
                                            <small>{item.date} {item.time}</small>
                                        </div>
                                    </div>
                                    <p>
                                    {item.reply_content}
                                    </p>
                                    {/* <div class="mt-3 flie_attache_support">
                                        <small><i class="fa fa-paperclip fa-2x" aria-hidden="true"></i> Attachments</small>
                                        <ul class="">
                                            <li><a href="">filename.pdf</a></li>
                                            <li><a href="">filename.jpg</a></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                            :item.reply_id==editmodals.transporter_id?
                            <div class="row">
                            <div class="col-12 col-md-1">
                                <img src={"https://dummyimage.com/50/1976d2/ffffff&text="+nameFormat(editmodals.full_name_transporter)} class="img-fluid" />
                            </div>
                            <div class="col-12 col-md">
                                <div class="row">
                                    <div class="col-12 col-md">
                                        <h3>{editmodals.full_name_transporter} ({editmodals.ticket_branch_name}) (Transporter)</h3>
                                    </div>
                                    <div class="col-12 col-md-4 text-end">
                                        <small>{item.date} {item.time}</small>
                                    </div>
                                </div>
                                <p>
                                {item.reply_content}
                                </p>
                                {/* <div class="mt-3 flie_attache_support">
                                    <small><i class="fa fa-paperclip fa-2x" aria-hidden="true"></i> Attachments</small>
                                    <ul class="">
                                        <li><a href="">filename.pdf</a></li>
                                        <li><a href="">filename.jpg</a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                            :  item.reply_id!=editmodals.customer_id?  <div class="row">
                            <div class="col-12 col-md-1">
                                <img src={"https://dummyimage.com/50/63a4ff/ffffff&text="+nameFormat(admindata.full_name)} class="img-fluid" />
                            </div>
                            <div class="col-12 col-md">
                                <div class="row">
                                    <div class="col-12 col-md">
                                        <h3>{admindata.full_name} (System Admin)</h3>
                                    </div>
                                    <div class="col-12 col-md-4 text-end">
                                        <small>{item.date} {item.time}</small>
                                    </div>
                                </div>
                                <p>
                                {item.reply_content}
                                </p>
                                {/* <div class="mt-3 flie_attache_support">
                                    <small><i class="fa fa-paperclip fa-2x" aria-hidden="true"></i> Attachments</small>
                                    <ul class="">
                                        <li><a href="">filename.pdf</a></li>
                                        <li><a href="">filename.jpg</a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>:""}
                    
                        </div>
                                ))}
                    </div>
                    {editmodals.status!=2 ?
                    <div class="py-3 comment_box">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    {/* <div class="col-12 col-md-1">
                                        <img src="https://i.imgur.com/3228qEZ.png" class="img-fluid" />
                                    </div> */}
                                    <div class="col-12 col-md">
                                        <div class="row">
                                            <div class="col-12 col-md">
                                                
                                            </div>
                                            <div class="col-12 col-md-4 text-end">
                                                <small></small>
                                            </div>
                                        </div>
                                        <textarea class="form-control" rows="5" id="comment" name="reply_content" placeholder="Please enter details" onChange={(e) => handleChange(e)} value={reply.reply_content}></textarea>
                                        <div class="row mt-2">
                                            {/* <div class="col-12 col-md-5">
                                                <input type="file" class="form-control" id="text" placeholder="Enter details name="/>
                                            </div> */}
                                            <div class="col-12 col-md text-end">
                                                <div class="">
                                                    <a  class="btn btn-primary" onClick={()=>handleSupportReply({ticket_id:editmodals.ticket_id})}>Send</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :""}
                </div>
             </div>
                    

    </section>

{/* <?php
include('footer.php');
?>   */}
</div>
  )
}

export default Tickets